import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AddImplantDeviceComponent } from "src/app/admin/add-implant-device/add-implant-device.component";
import {
  DialogOptions,
  ConfirmDialogComponent,
  DialogDefaults,
} from "src/app/components/confirm-dialog/confirm-dialog.component";
import { InfoDialogComponent } from "src/app/components/info-dialog/info-dialog.component";
import { ModalChangepasswordComponent } from "src/app/components/modal-changepassword/modal-changepassword.component";
import { ModalDatepickerComponent } from "src/app/components/modal-datepicker/modal-datepicker.component";
import { ModalStatisticsComponent } from "src/app/components/modal-statistics/modal-statistics.component";
import { ModalSuccessmessageComponent } from "src/app/components/modal-successmessage/modal-successmessage.component";
import { AssignedLessonComponent } from "src/app/rehab/pre-lingual/patient-details/assigned-lesson/assigned-lesson.component";

@Injectable({
  providedIn: "root",
})
export class UiUtilService {
  model: NgbModalRef;
  showStats: NgbModalRef;
  constructor(private modalService: NgbModal) {}

  showConfirmDialog(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
    });
    modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }
  showInfoDialog(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(InfoDialogComponent, {
      centered: true,
    });
    modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }

  showDatepickerDialog(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(ModalDatepickerComponent, {
      centered: true,
    });
    modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }

  showChangePasswordDialog(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(ModalChangepasswordComponent, {
      centered: true,
    });
    modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }

  showChangePasswordSuccessMsg(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(ModalSuccessmessageComponent, {
      centered: true,
    });
    modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }

  showStatisticsModal(dialogOptions: DialogOptions): NgbModalRef {
    const modelRef = this.modalService.open(ModalStatisticsComponent, {
      centered: true,
      windowClass:'statistics-modal'
    });
    // modelRef.componentInstance.message = dialogOptions.message;
    modelRef.componentInstance.data = dialogOptions.data;
    modelRef.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return modelRef;
  }

  showActivitiesStatistics(dialogOptions: DialogOptions): NgbModalRef {
    this.showStats = this.modalService.open(AssignedLessonComponent, {
      centered: true,
      windowClass:'statistics-modal'
    });
    // modelRef.componentInstance.message = dialogOptions.message;
    this.showStats.componentInstance.data = dialogOptions.data;
    this.showStats.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;
    return this.showStats;
  }

  showDeviceInfo(dialogOptions: DialogOptions): NgbModalRef {
    this.model = this.modalService.open(AddImplantDeviceComponent, {
      centered: true,
    });
    this.model.componentInstance.data = dialogOptions.message;
    this.model.componentInstance.title = dialogOptions.title
      ? dialogOptions.title
      : DialogDefaults.TITLE;

    return this.model;
  }

  DismissDeviceInfo() {
    this.model.close();
  }
  DismissshowActivitiesStatistics() {
    this.showStats.close();
  }
  
}
