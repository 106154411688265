import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { SplitpageComponent } from "./splitpage/splitpage.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "welcome", component: SplitpageComponent },
  // {
  //   path: "dashboard",
  //   loadChildren: () =>
  //     import("./rehab/rehab.module").then((m) => m.RehabModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "patientDetails",
  //   loadChildren: () =>
  //     import("./rehab/rehab.module").then((m) => m.RehabModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "lessons",
  //   loadChildren: () =>
  //     import("./rehab/rehab.module").then((m) => m.RehabModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: "therapist",
    loadChildren: () =>
      import("./rehab/rehab.module").then((m) => m.RehabModule),
    canActivate: [AuthGuard],
  },

  // {
  //   path: "postLingual",
  //   loadChildren: () =>
  //     import("./rehab/rehab.module").then((m) => m.RehabModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "reports-post",
  //   loadChildren: () =>
  //     import("./rehab/post-lingual/reports-post/reports-post.module").then(
  //       (m) => m.ReportsPostModule
  //     ),
  // },

 
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
 
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
