// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//  const BASE_URL = "http://3.131.191.8120088/";
// const LOGIN_BASE_URL = "http://3.131.191.81:8081/";


// const BASE_URL = "http://10.132.129.32:20088/";
// const LOGIN_BASE_URL = "http://10.132.129.32:8081/";
// const BASE_URL = "http://localhost:1500/";
// const LOGIN_BASE_URL = "http://localhost:1500/";
 const BASE_URL = "https://mymedtechapp.com:20088/";
  const LOGIN_BASE_URL = "https://mymedtechapp.com:8081/";

//  const BASE_URL = "http://localhost:20088/";
//  const LOGIN_BASE_URL = "http://localhost:8081/";


// const BASE_URL = "https://mymedtechapp.com:20088/";
// const LOGIN_BASE_URL = "https://mymedtechapp.com:20090/";

// New server instance -Ip Addresslogin
//  const BASE_URL = "http://10.132.129.6:20088/";
// const LOGIN_BASE_URL = "http://10.132.129.6:8081/";

// // Load Balancing URL
// const BASE_URL = "https://medtech-lb-306133066.us-east-1.elb.amazonaws.com:20088/";
// const LOGIN_BASE_URL = "https://medtech-lb-306133066.us-east-1.elb.amazonaws.com:8081/";

//Old Instance -Public IP
// const BASE_URL = "http://3.131.191.81:20088/";
// const LOGIN_BASE_URL = "http://3.131.191.81:8081/";

// const BASE_URL = "http://3.134.190.226:20088/";
// const LOGIN_BASE_URL = "http://3.134.190.226:8081/";


//  const BASE_URL = "https://prod.mymedtechapp.com:20088/";
// const LOGIN_BASE_URL = "https://prod.mymedtechapp.com:8081/";

export const environment = {
  production: true,
  environmentName: "local",
  apiUrl: BASE_URL,
  loginApiUrl: LOGIN_BASE_URL,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
