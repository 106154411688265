<div>
    <div class="modal-header text-left">
        <h6 style="margin-bottom: 0rem;font-weight: 700;font-size: 14px !important;">{{message}}</h6>

        <div class="icon-mt-close-gray icon2" ngbTooltip="cancel" tooltipClass="my-custom-class" placement="bottom" (click) = "onCancel()"></div>
    </div>
    <div class="row" style="padding: 1.5rem;">
        <div class="col col-6 divider">
            <app-date-picker (onDateSelect)="startDateSelection($event)" id="lessonStartDate" [disabled]="false"
                [outlined]="false" [defaultValue]="startDate" [label]="'Start Date'" [minDate]="lastMinDate" >
            </app-date-picker>
        </div>
        <div class="col col-6">
            <app-date-picker (onDateSelect)="lastDateSelection($event)" id="lessonEndDate"
                [disabled]="startDate ? false : true" [outlined]="false" [defaultValue]="lastDate" [label]="'To Date'" [minDate]="lastMinDate" >
            </app-date-picker>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <app-button id="addLessonClose" [iconClass]="'icon-mt-close-white'" [buttonText]="'Close'" (click)="onCancel()">
        </app-button> -->
        <!-- <app-button id="addLessonSave" [disabled]="lastDate ? false : true" (click)="onOk()"
            [iconClass]="'icon-mt-savenew'" [buttonText]="'Save'"></app-button> -->
            <div class="btn btn btn-main btn-rounded add-over" [ngClass]="lastDate ? 'ok' : 'notOk'" (click)="onOk()" >
                <div class="icon-mt-savenew mr-clr"></div>
                  Save
                </div>
    </div>
</div>