import { Component, OnInit, HostListener } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LocalStorageService } from "../_services/local-storage/localstorage.service";
import constant from "src/constants/MTConstants";
import { UserAuthenticationService } from "../_services/user-authentication.service";
import { AESEncryptDecryptService } from "../_services/AESEncryptDecryptService";
import { EncrDecrService } from "../_services/encr-decr.service";
import { StringService } from "src/app/_services/string.service";
import { RestApiService } from "../_services/rest-api/rest-api.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  myForm: FormGroup;
  images: any[] = [
    "/assets/imgs/168_ic_login_slide_bgcolor_01.svg",
    "/assets/imgs/169_ic_login_slide_bgcolor_02.svg",
    "/assets/imgs/170_ic_login_slide_bgcolor_03.svg",
  ];
  theCheckbox = false;
  srcWidth: any;
  leftPanelFlag: boolean = true;
  cookieValue: any;
  rememberMe: boolean = false;
  copyrightText: string =
    constant.loginScreen.copywrightSymbol +
    new Date().getFullYear() +
    constant.loginScreen.copywrightText;
  therapistFullName: string;
  therapistId: any;

  constructor(
    private api: RestApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private localStorage: LocalStorageService,
    private authenticationService: UserAuthenticationService, // For encryption and decryption we need to enable this.
    // private encrypt: AESEncryptDecryptService,
    private EncrDecr: EncrDecrService // private encrypt: AESEncryptDecryptService,
  ) // private EncrDecr: EncrDecrService
  {
    this.getScreenSize();
  }
 

  ngOnInit(): void {
    if (Boolean(this.localStorage.get(constant.loginScreen.rememberMe))) {
      let json = this.localStorage.get(constant.loginScreen.credentials);
      let credentials = JSON.parse(json);
      this.loginForm = this.formBuilder.group({
        userName: [credentials.userName, [Validators.required,Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$")]],
        password: [credentials.password, Validators.required],
      });
      this.rememberMe = Boolean(
        this.localStorage.get(constant.loginScreen.rememberMe)
      );
    } else {
      this.loginForm = this.formBuilder.group({
        userName: [constant.emptyString, Validators.required],
        password: [constant.emptyString, Validators.required],
      });
      this.rememberMe = Boolean(
        this.localStorage.get(constant.loginScreen.rememberMe)
      );
    }
  }
  onSubmit() {
    this.localStorage.set(
      constant.loginScreen.rememberMe,
      this.rememberMe ? this.rememberMe.toString() : ""
    );
    if (this.rememberMe) {
      let credentials = JSON.stringify(this.loginForm.value);
      this.localStorage.set(constant.loginScreen.credentials, credentials);
    }
    let credentials = JSON.stringify(this.loginForm.value);
    this.localStorage.set(constant.loginScreen.credentials, credentials);

    let loginParameter = {
      loginName: this.loginForm.value.userName,
      password: this.loginForm.value.password,
      org: constant.org,
    };

    this.authenticationService.onLogin(loginParameter).subscribe((response) => {
      if (response) {
        //Server
        console.log(response);

        this.localStorage.set(
          constant.patientProgressKey,
          constant.patientProgressValue
        );
        this.localStorage.set(
          constant.underPerformingKey,
          constant.underPerformingValue
        );
        this.localStorage.set(
          constant.wellPerformingKey,
          constant.wellPerformingValue
        );
        this.localStorage.set(
          constant.inactivePatientKey,
          constant.inactivePatientValue
        );
        this.localStorage.set(
          constant.averagePerformingKey,
          constant.averagePerformingValue
        );

        // console.log("healthrequesttBody", healthrequesttBody);
        //  console.log("credentials json", credentials);
        //     var encryptedJson = this.EncrDecr.set(JSON.stringify(credentials));
        //    console.log("encrypted json", encryptedJson);
        //    this.localStorage.set(constant.loginScreen.credentials,encryptedJson);

        //    var decryptedJson = this.EncrDecr.get(this.localStorage.get(constant.loginScreen.credentials));

        //    let json = this.localStorage.get(constant.loginScreen.credentials);
        //    var decryptedJson = this.EncrDecr.get(json);
        //      console.log("decrypted json", decryptedJson);

        // this.api.healthcheckapi(encryptedJson).subscribe((res) => {
        //   console.log("health check api", res);

        //   var decryptedJson = this.EncrDecr.get(res);
        //   console.log("decrypted json", decryptedJson);
        // });

        //   const encryptedVideoData = this.EncrDecr.encryptvideo("https://mymedtechapp.com/medtech-content/PostLingual/en/Basic/SoundAwareness/Loud/Banging_Hammer.mp4", 'sampleVideoncrypt');
        //  const blob = new Blob([encryptedVideoData], { type: 'video/mp4' });
        //  const blobUrl = URL.createObjectURL(blob);
        //  console.log("blobUrl" , blobUrl);
        var sss = btoa(response.role);
        console.log("sss", sss);
        console.log("blobUrl", atob(sss));
        this.localStorage.set(constant.loginScreen.userToken, response.cookie);
        if (
          StringService.compareString(
            response.role,
            constant.loginScreen.therapist
          )
        ) {
          this.localStorage.set(
            constant.loginScreen.therapistId,
            response.therapistId
          );
          this.localStorage.set(constant.loginScreen.adminId, response.adminId);
          this.therapistId=response.therapistId;
          this.localStorage.set(constant.flowType,constant.postLingual);
          // this.router.navigateByUrl(constant.loginScreen.welcome);
          this.getTherapistDetailById();
          this.router.navigate(["therapist/postLingual/dashboard/stats"]);
        } else if (
          StringService.compareString(response.role, constant.admin)
        ) {
          this.localStorage.set(constant.loginScreen.adminId, response.adminId);
          this.localStorage.set(constant.loginScreen.careId, response.careId);
          this.localStorage.set(constant.flowType, constant.admin);
          this.router.navigateByUrl(constant.loginScreen.admin);
        }
      }
    });
  }

  onForgotPassword() {
    let request = {
      loginName: this.loginForm.value.userName,
      // password: this.loginForm.value.password,
      org: constant.org,
    };
    this.authenticationService
      .OnforgotPassword(request)
      .subscribe((response) => {
        console.log("New Login Response", response);
      });
  }

  // on click of remember me check box
  toggleVisibility(e) {
    this.rememberMe = e.target.checked;
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.srcWidth = window.innerWidth;
    //console.log(this.srcWidth);
    this.leftPanelFlag = this.srcWidth > 1200;
  }
  newGoalPoints(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }

  getTherapistDetailById() {
    let therapistDetails = {
      id: this.therapistId,
    
    };
    this.api.getTherapistDetailsById(therapistDetails).subscribe((response) => {
      console.log("therapist Details",response)
      this.therapistFullName = StringService.joinString(response.data.therapistFirstName,response.data.therapistLastName)
      // this.nameCompare = StringService.compareString("san","san")
      console.log("therapist Details",this.therapistFullName)
      
      // this.therapistFullName =  response.data.therapistFirstName + " " + response.data.therapistLastName;
      this.localStorage.set(constant.loginScreen.therapistName,this.therapistFullName);
     });
  }
}
