import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { from, Subscription } from "rxjs";
import { AutoLogoutService } from "../app/_services/autologout.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  title = "rehab";
  mySubscription = new Subscription();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private autologout: AutoLogoutService
  ) {
    translate.setDefaultLang("en");
    this.useLang("en");
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  onLanguageChange(value: any): void {
    this.useLang(value);
  }

  useLang(langCode: string): void {
    this.translate.use(langCode);
  }
}
