import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RestApiService } from "../rest-api/rest-api.service";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  selectedPatient: any;
  patientList: any[] = [];
  selectedPatientRow: any;
  selectedPatientPl = new Subject();

  constructor(private api: RestApiService) {}

  addNewPatient(patientDetails: any): Observable<any> {
    // this.patientList.push(patientDetails);
    return this.api.createNewPatient(patientDetails);
  }

  deletePatient(selectedpatientData: any): void {
    const index = this.patientList.indexOf(selectedpatientData);
    if (index > -1) {
      this.patientList.splice(index, 1);
    }
    // return this.api.deletePatientData(selectedpatientData.id);
  }

  set currentSelectedPatient(patient: any) {
    this.selectedPatient = patient;
  }

  get currentSelectedPatient(): any {
    return this.selectedPatient;
  }

  clearData(): void {
    this.selectedPatient = null;
    this.patientList = [];
  }
}
