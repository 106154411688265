import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PatientService } from "./patient/patient.service";
import constant from "src/constants/MTConstants";
import { LocalStorageService } from "./local-storage/localstorage.service";

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in mins
const CHECK_INTERVAL = 50; // in ms
const STORE_KEY = "lastAction";
@Injectable({
  providedIn: "root",
})
export class AutoLogoutService {
  val: any;
  public getLastAction() {
    return parseInt(this.localStorage.get(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    this.localStorage.set(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private patientService: PatientService,private localStorage: LocalStorageService,) {
    // this.check();
    // this.initListener();
    // this.initInterval();
    this.localStorage.set(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener("click", () => this.reset());
    document.body.addEventListener("mouseover", () => this.reset());
    document.body.addEventListener("mouseout", () => this.reset());
    document.body.addEventListener("keydown", () => this.reset());
    document.body.addEventListener("keyup", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
    window.addEventListener("storage", () => this.storageEvt());
  }

  reset() {
    // console.log('date got by using events',Date.now());
    this.setLastAction(Date.now());
    // console.log('store key',localStorage.getItem(STORE_KEY));
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    // console.log('difference',diff)
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.patientService.clearData();
      sessionStorage.clear();

      this.localStorage.remove(constant.loginScreen.userToken);
      this.router.navigate(["/"]);
    }
  }
  storageEvt() {
    //   console.log("storage");
    this.val = this.localStorage.get(STORE_KEY);
  }
}
