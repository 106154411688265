import { Injectable, Output } from "@angular/core";
import { Subject } from "rxjs";
import { RestApiService } from "../rest-api/rest-api.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  @Output() genderSubject = new Subject();

  constructor(private api: RestApiService) {

  }
}
