import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-tile-block",
  templateUrl: "./tile-block.component.html",
  styleUrls: ["./tile-block.component.scss"],
})
export class TileBlockComponent implements OnInit {
  @Input() tileHeader: string;
  @Input() tileContent: string;
  @Input() tileContent1: string;
  @Input() tileContent2: string;
  @Input() dropdownSelected: any;
  @Input() dropdownLists: any;
  @Input() tyleType: number;
  @Input() tileColor: any;
  @Input() tileCount1: number;
  @Input() tileCount2: number;
  @Output() tileDropdownSel = new Subject();

  constructor() {}

  ngOnInit(): void {}

  onTileDropdown($event) {
    this.tileDropdownSel.next($event);
  }
}
