import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  selector: 'app-dropdown-for-course-content',
  templateUrl: './dropdown-for-course-content.component.html',
  styleUrls: ['./dropdown-for-course-content.component.scss']
})
export class DropdownForCourseContentComponent implements OnInit {

  @Input() inputLists: any;
  @Input() selected: any;
  @Input() disabled: boolean;
  @Input() dropdownId: string = "";
  @Input() outlined: string = "fullBorder";
  @Output() onDropdownSelect = new Subject();
  @ViewChild("input") inputRef: ElementRef;
  value: any = "";
  @Input() placeholder: string = "Select";
  isFocus: boolean = false;
  @Input() selectedVal: boolean = false;
  
  @Input()SelectHere: string = "";
  


  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) { 
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  onOptionsSelected(e) {
    this.selected = e;
    this.isFocus = true;
    this.onDropdownSelect.next(this.selected);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onOptionsSelected = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputBlur = fn;
  }

  onInputFocus() {
    this.isFocus = true;
    this.inputRef.nativeElement.focus();
  }

  onInputBlur() {
    this.isFocus = false;
    if (this.selected != "") {
      this.isFocus = true;
    }
  }


}


