import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

export enum DialogDefaults {
  OK_BUTTON_TEXT = "Confirm",
  CANCEL_BUTTON_TEXT = "Cancel",
  TITLE = "Confirm",
}

@Component({
  selector: "app-modal-datepicker",
  templateUrl: "./modal-datepicker.component.html",
  styleUrls: ["./modal-datepicker.component.scss"],
})
export class ModalDatepickerComponent implements OnInit {
  @Input() startDate: any;
  @Input() lastDate: any;
  endDateDisableFlag: boolean = true;
  @Output() startDateSelect = new Subject();
  @Output() lastDateSelect = new Subject();
  @Input() message: string;
  @Input() title = DialogDefaults.TITLE;
  lastMinDate: NgbDateStruct;
  startMinDate: NgbDateStruct;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const current = new Date();
    this.startMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    this.lastMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
 
  }

  getNotificationCount(): number {
    return 4;
  }

  startDateSelection($event) {
    this.startDate = $event;
    this.lastDate = "";
    this.lastMinDate = { ...this.startDate };
  }
  lastDateSelection($event) {
    this.lastDate = $event;
  }
  onCancel(): void {
    let datepickerObj = {
      flag: false,
      startDate: this.startDate,
      lastDate: this.lastDate,
    };
    this.activeModal.close(datepickerObj);
  }

  onOk(): void {
    let datepickerObj = {
      flag: true,
      startDate: this.startDate,
      lastDate: this.lastDate,
    };
    // console.log(datepickerObj);
    this.activeModal.close(datepickerObj);
  }
}
