<div class="containerDiv">
    <div class="modal-header text-left">
        <h6>{{message}}</h6>
    </div>
    <div class="icon-mt-close-white closeIcon" (click)="onClose()"></div>
    <div class="formRow">
        <form [formGroup]="changePasswordForm" class="formElement">
            <div>
                <app-password-textfield  (change)="validateoldpassword()" id="oldPassword" [defaultValue]="changePasswordForm['controls'].oldPassword.value"
                formControlName="oldPassword" [placeholder]="'Old Password'" [type]="'text'" [label]="''"
                [disabled]="false">
                </app-password-textfield>
                <div class="errorDiv" [hidden]="!oldpasswordErrorMsg">
                    <div class="errorMsg">{{oldpasswordErrorMsg}}</div>
                </div>
            </div>
            <div>
                <app-password-textfield (ngModelChange)="ValidateNewPassword()"  id="newPassword" [defaultValue]="changePasswordForm['controls'].newPassword.value"
                formControlName="newPassword" [placeholder]="'New Password'" [type]="'text'" [label]="''"
                [disabled]="(changePasswordForm['controls'].oldPassword.value) && oldPasswordError"></app-password-textfield>
                <!-- <div class="errorDiv" *ngIf="newpasswordErrormsg">
                    <div class="errorMsg">{{newpasswordErrormsg}}</div>
                </div> -->
            </div>
            <div class="User_message_box">
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('length')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('length')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    Has minimum 8 characters and max 16 characters!
                </label>
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('startsWithLetter')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('startsWithLetter')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    Must start with letter!
                </label>
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSpecialCharacters')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSpecialCharacters')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    {{this.spclErrormessage}}
                </label>
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasCapitalCase')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    Must contain atleast one uppercase letter!
                </label>
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSmallCase')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSmallCase')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    Must contain atleast one lowercase letter!
                </label>
                <label class="col message_text" [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasNumber')  ? '' :'text-success'">
                    <div [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasNumber')  ? 'icon-mt-wrong' :'icon-mt-selected'" >
                    </div>
                    Must contain atleast one digit!
                </label>
            </div>

            <div >
                <app-password-textfield (ngModelChange)="ValidateConfirmPassword()" id="confirmPassword" [defaultValue]="changePasswordForm['controls'].confirmPassword.value"
                formControlName="confirmPassword" [placeholder]="'Confirm Password'" [type]="'text'" [label]="''"
                [disabled]="((changePasswordForm['controls'].newPassword.status == 'INVALID') && (changePasswordForm['controls'].newPassword.value))"></app-password-textfield>
                <div class="errorDiv" *ngIf="confirmpasswordmsg">
                    <div class="errorMsg">{{confirmpasswordmsg}}</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <app-button id="changePassSubmit" [disabled]="!((this.changePasswordForm['controls'].newPassword.value == this.changePasswordForm['controls'].confirmPassword.value) && confirmflag && (this.changePasswordForm.valid))" (click)="onSubmit()"
            [buttonText]="'Submit'"></app-button>
    </div>
</div>