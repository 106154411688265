<div appDisableCopyCutPaste>
<div class="login">

  <div *ngIf="leftPanelFlag" class="col col-lg-5 col-sm-12 bg"> 
    <div class="cidiv">
      <img src="/assets/imgs/173_ic_HCL_Suno_latest_logo.svg" height="60" />
    </div>
    <!-- <ngb-carousel *ngIf="images" class="heightdiv">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="widthdiv" [src]="images[0]" alt="Random first slide" />
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[1]" class="widthdiv" alt="Random second slide" />
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[2]" class="widthdiv" alt="Random third slide" />
        </div>
      </ng-template>
    </ngb-carousel> -->

  </div>
  <div class="col col-lg-7 col-sm-12 colordiv">
    <div class="welcomeDiv" i18n>Welcome User</div>
    <!-- <div class="welcomeDiv">{{ "LOGIN_SCREEN.WELCOME_USER" | translate }}</div> -->
    <div class="formDiv">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="container">
          <app-textfield
            id="userName"
            [defaultValue]="loginForm['controls'].userName.value"
            formControlName="userName"
            [type]="'email'"
            [placeholder]="'Email Id'" email>
          </app-textfield>
          <div class="errorDiv" *ngIf="loginForm['controls'].userName.touched && loginForm['controls'].userName.status == 'INVALID'">
            <div class="errorMsg">
                Please enter valid email id</div>
          </div>
        </div>
        <div class="mar" class="container">
          <app-password-textfield
            id="password"
            [defaultValue]="loginForm['controls'].password.value"
            formControlName="password"
            [placeholder]="'Password'"
            [type]="'text'">
          </app-password-textfield>
        </div>
        <div class="check">
          <div class="flexDiv">
            <input
              type="checkbox"
              id="rememberMe"
              [checked]="rememberMe"
              class="checkbox1"
              data-md-icheck
              (change)="toggleVisibility($event)"
            />
            <label for="rememberMe"></label>
            <div class="rememberSpan paddingDiv" class="paddingDiv">
              {{ "LOGIN_SCREEN.REMEMBER_ME" | translate }}
            </div>
          </div>

          <div (click)="onForgotPassword()" class="flexg" class="forgotPW">
            {{ "LOGIN_SCREEN.FORGOT_PASSWORD" | translate }}
          </div>

          <!-- <app-button id="resetBtn" [buttonText]="'Forgot Password'" (click)="onForgotPassword()"></app-button> -->
        </div>
        <div class="signinDIv">
          <app-button
            id="signinBtn"
            [disabled]="!loginForm.valid"
            [buttonText]="'login'"
          ></app-button>
        </div>
      </form>
    </div>
  </div>
</div>
<footer class="foot">
  <div class="wrapper">
    <p class="p-small font-regular mb-zero">
      {{ copyrightText }}
    </p>
  </div>
</footer>
</div>