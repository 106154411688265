import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/_services/local-storage/localstorage.service';
import { UiUtilService } from 'src/app/_services/ui-utils/ui-util.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  sideNavRadioObject: any = { adminPatientDetailsFlag: true, deviceMantainanceFlag: false, 
    therapistCreationFlag: false, defaultCourseFlag: false, courseDetailsFlag: false }
  close: boolean = true;
  cnfrmDialog: any;

  constructor(private uiUtils: UiUtilService,) { }

  setSideNavRadioObject(value: object) {
    this.sideNavRadioObject = value;
  }
  getSideNavRadioObject() {
    return this.sideNavRadioObject;
  }

  //On click of edit ,DeviceMainatenance Pop-up
  Onedit() {
    if (this.close == true) {
      this.cnfrmDialog = this.uiUtils.showDeviceInfo({
        message: "",
      });
      this.cnfrmDialog.result.then((res) => {
        if (res == true) {
        }
      });

    } else if (this.close == false) {
      this.cnfrmDialog = this.uiUtils.DismissDeviceInfo();
    }
  }
  //On click of Add-device DeviceMainatenance Pop-up
  onClickAdd() {
    if (this.close == true) {
      this.cnfrmDialog = this.uiUtils.showDeviceInfo({
        message: "",
      });
      this.cnfrmDialog.result.then((res) => {
        if (res == true) {
        }
      });
    } else if (this.close == false) {
      this.cnfrmDialog = this.uiUtils.DismissDeviceInfo();
    }
  }


}
