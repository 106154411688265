import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { PatientService } from "src/app/_services/patient/patient.service";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { UiUtilService } from "src/app/_services/ui-utils/ui-util.service";
import { AdminService } from "../services/admin.service";
import constant from "src/constants/MTConstants";

@Component({
  selector: "app-add-implant-device",
  templateUrl: "./add-implant-device.component.html",
  styleUrls: ["./add-implant-device.component.scss"],
})

export class AddImplantDeviceComponent implements OnInit {
  implantDeviceForm: FormGroup = new FormGroup({});
  SpDeviceForm: FormGroup = new FormGroup({});
  isEditAction: boolean = false;
  private _success = new Subject<string>();
  public deviceTypes: any[] = [
    { label: constant.adminScreen.implant, checked: true, disabled: false },
    { label: constant.adminScreen.speechProcessor, checked: false, disabled: false },];
  successMessage = "";
  submitted: boolean;
  selectedDevice: any;
  dropdownSelected: string;
  dropdownLists: any[] = ["true", "false"];
  firstNameTouched: boolean = false;
  deviceDetails: any;
  adminId: number;
  radiovalue: string = this.localStorage.get(constant.adminScreen.deviceType);
  cnfrmDialog: any;
  editing: boolean = this.localStorage.get(constant.adminScreen.editDeviceDetails);

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router,
    private api: RestApiService,
    private uiUtils: UiUtilService,
    private adminservice: AdminService

  ) {}

  ngOnInit(): void {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(2000)).subscribe(() => {
      this.successMessage = "";
      this.cnfrmDialog = this.uiUtils.DismissDeviceInfo();
      this.router.navigateByUrl(constant.RoutingUrl.deviceMaintainance);
    });
    this.adminId = parseInt(this.localStorage.get(constant.loginScreen.adminId));
    this.getQueryParams();
  }
  //intialize form of Implant for Add
  initializeImpDeviceForm(formData: any): void {
    this.implantDeviceForm = this.fb.group({
      implantSerialnumber: [formData.implantSerialnumber, Validators.required],
      implantModelName: [formData.implantModelName, Validators.required],
      is_assigned: [formData.is_assigned, Validators.required],
      comments: [formData.comments]
    });
  }

  //intialize form of Implant for Edit
  initializeImpDeviceForm2(formData: any): void {
    this.implantDeviceForm = this.fb.group({
      implantModelName: [formData.modelName, Validators.required],
      implantSerialnumber: [formData.serialNumber, Validators.required],
      is_assigned: [formData.status, Validators.required],
      comments: [formData.comments]
    });
  }

  //intialize form of Sp for Add
  initializeSpDeviceForm(formData: any): void {
    this.SpDeviceForm = this.fb.group({
      spSerialnumber: [formData.spSerialnumber, Validators.required],
      spModelName: [formData.spModelName, Validators.required],
      comments: [formData.comments],
      is_assigned: [formData.is_assigned, Validators.required],
    });
  }
  //intialize form of Sp for Edit
  initializeSpDeviceForm2(formData: any): void {
    this.SpDeviceForm = this.fb.group({
      spModelName: [formData.modelName, Validators.required],
      spSerialnumber: [formData.serialNumber, Validators.required],
      is_assigned: [formData.status, Validators.required],
      comments: [formData.comments],
    });
  }


//On click of Save or Update
  onAddButtonClick(): void {
    this.submitted = true;
    if (this.isEditAction) {
      if (this.implantDeviceForm.dirty && this.implantDeviceForm.valid) {
        this.updateImpDeviceInfo();
      }
      else if (this.SpDeviceForm.dirty && this.SpDeviceForm.valid) {
        this.updateSpDeviceInfo()
      }
    } else if (this.radiovalue==constant.adminScreen.implant) {
      this.addImpDeviceInfo()
    }
    else if (this.radiovalue==constant.adminScreen.speechProcessor) {
      this.addSpDeviceInfo();
    }

  }

  // On Click of Save
  addImpDeviceInfo(): void {
    let addDeviceDetails = {
      implantSerialnumber: {implantSerialnumber: this.implantDeviceForm.value.implantSerialnumber, is_assigned: this.implantDeviceForm.value.is_assigned},
      implantModel: {implantModelName: this.implantDeviceForm.value.implantModelName},
      is_assigned: this.implantDeviceForm.value.is_assigned,
      comments: this.implantDeviceForm.value.comments,
      adminId: this.adminId
    };

    this.api.saveImplantDeviceDetails(addDeviceDetails).subscribe((res) => {
      if (res) {
        window.scroll(0, 0);
        this._success.next(res.message);
      }
    });
  }

  addSpDeviceInfo(): void {
    let addDeviceDetails = {
      spSerialnumber: {spSerialnumber: this.SpDeviceForm.value.spSerialnumber, is_assigned: this.SpDeviceForm.value.is_assigned},
      spModel: {spModelName: this.SpDeviceForm.value.spModelName},
      is_assigned: this.SpDeviceForm.value.is_assigned,
      comments: this.SpDeviceForm.value.comments,
      adminId: this.adminId,
    };

    this.api.saveBteDeviceDetails(addDeviceDetails).subscribe((res) => {
      if (res) {
        window.scroll(0, 0);
        this._success.next(res.message);
      }
    });
  }

  //On Click of Update
  updateImpDeviceInfo(): void {
    this.selectedDevice = this.localStorage.get(constant.adminScreen.selectedDeviceDetails);
    let updateDeviceDetails = {
      implantSerialnumber: {implantSerialnumber: this.implantDeviceForm.value.implantSerialnumber, is_assigned: this.implantDeviceForm.value.is_assigned},
      implantModel: {implantModelName: this.implantDeviceForm.value.implantModelName},
      is_assigned: this.implantDeviceForm.value.is_assigned,
      comments: this.implantDeviceForm.value.comments,
      adminId: this.adminId,
      id: this.selectedDevice.id
    };
    this.api.updateImplantDeviceDetails(updateDeviceDetails).subscribe((res) => {
      if (res && this.implantDeviceForm.valid) {
        window.scroll(0, 0);
        this._success.next(res.message);
      }
    });
  }

  updateSpDeviceInfo(): void {
    this.selectedDevice = this.localStorage.get(constant.adminScreen.selectedDeviceDetails);
    let updateDeviceDetails = {
      spSerialnumber: {spSerialnumber: this.SpDeviceForm.value.spSerialnumber, is_assigned: this.SpDeviceForm.value.is_assigned},
      spModel: { spModelName: this.SpDeviceForm.value.spModelName},
      is_assigned: this.SpDeviceForm.value.is_assigned,
      comments: this.SpDeviceForm.value.comments,
      id: this.selectedDevice.id,
      adminId: this.adminId,
    };

    this.api.updateBteDeviceDetails(updateDeviceDetails).subscribe((res) => {
      if (res && this.SpDeviceForm.valid) {
        window.scroll(0, 0);
        this._success.next(res.message);
      }
    });
  }

  getQueryParams(): void {
    if (this.editing == true) {
      this.isEditAction = true;
      this.prePopulatePatientData();
    } else {
      this.isEditAction = false;
      this.initializeSpDeviceForm({
        spModelName: "",
        spSerialnumber: "",
        is_assigned: "",
        comments: "",

      });
      this.initializeImpDeviceForm({
        implantModelName: "",
        implantSerialnumber: "",
        is_assigned: "",
        comments: "",
      });
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  prePopulatePatientData(): void {
    this.deviceDetails = this.localStorage.get(constant.adminScreen.selectedDeviceDetails);
    if (this.deviceDetails.type == constant.adminScreen.implant) {
      this.initializeImpDeviceForm2(this.deviceDetails);
    }
    else if (this.deviceDetails.type == constant.adminScreen.speechProcessor) {
      this.initializeSpDeviceForm2(this.deviceDetails);
    }
  }

  onTileDropdown(event: any) { }

  // On click of radio buttons
  Onchange($event) {
    if (this.isEditAction == false) {
      if ($event == constant.adminScreen.implant) {
        this.localStorage.set(constant.adminScreen.deviceType, $event)
        this.radiovalue = constant.adminScreen.implant;
      }
      else if ($event == constant.adminScreen.speechProcessor) {
        this.localStorage.set(constant.adminScreen.deviceType, $event)
        this.radiovalue = constant.adminScreen.speechProcessor;
      }
    }
  };

  //On click cancel icon
  onClose() {
    this.cnfrmDialog = this.uiUtils.showConfirmDialog({
      message: constant.message.close,
    });
    this.cnfrmDialog.result.then((res) => {
      if (res == true) {
        this.cnfrmDialog = this.uiUtils.DismissDeviceInfo();
        this.router.navigateByUrl(constant.RoutingUrl.deviceMaintainance);
      }
    });
  }
}


