import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-chart-base",
  template: "",
  styleUrls: ["./chart-base.component.scss"],
})
export class ChartBaseComponent implements OnInit {
  @Input() public datasets: any;
  @Input() public labels: any;
  @Input() public options: any;
  @Input() public legend: boolean;
  @Input() public colors: any;

  @Output() public chartHover = new Subject<any>();
  @Output() public chartClick = new Subject<any>();

  constructor() {}

  ngOnInit(): void {}

  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    this.chartClick.next(event);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    this.chartHover.next(event);
  }
}
