import { Component, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() iconClick = new Subject();

  constructor() {}

  ngOnInit(): void {}
  onIconClick($event) {
    this.iconClick.next($event);
  }
}
