export const MOCK_DB = {
    scheduleSummaryList: [
      {
        "patients": {
          "totalPatients": 20,
          "activePatients": 15
        },
        "courseCount": [
          {
            "completed": 20,
            "inProgress": 10,
            "failed": 36,
            "title": "basic"
          },
          {
            "completed": 20,
            "inProgress": 80,
            "failed": 36,
            "title": "intermediate"
          },
          {
            "completed": 20,
            "inProgress": 48,
            "failed": 36,
            "title": "Advance"
          }
        ]
      }
    ],
    assignedLessons: [
        {patientName: 'WillSmith', lessonCategory: 'Basic/Train', completed: '06', total: '10'},
        {patientName: 'John', lessonCategory: 'Basic/Train', completed: '03', total: '10'},
        {patientName: 'Jayseelan', lessonCategory: 'Advance/Train', completed: '04', total: '10'},
        {patientName: 'Sathish Kumar', lessonCategory: 'Basic/Train', completed: '10', total: '10'},
        {patientName: 'Ranganathan', lessonCategory: 'Basic/Train', completed: '09', total: '10'},
        {patientName: 'Blessy Arputham', lessonCategory: 'Advance/Train', completed: '06', total: '10'},
    ],
    notifications: [
        {id: 1, title: 'Doctor Feedback', count: 5},
        {id: 2, title: 'Patient Feedback', count: 7},
        {id: 3, title: 'Lessons Update', count: 10},
        {id: 4, title: 'Others', count: 2},
    ],
    patientsList: [
      {
          "firstname": "Will", "lastname": "Smith",
          "dob": "1987-12-06", "gender": "Male",
          "comments": "Lorem ipsum dolar sit amet, adipiscing edit, sed", "notes": "Test",
          "email": "example@gmail.com", "id": 1,
          "mobile_number": "8739651398", "vacc_detail": "Yes",
          "profilepic": ""
      },
      {
          "firstname": "William", "lastname": "Cornor",
          "dob": "2000-12-06", "gender": "Male",
          "comments": "Lorem ipsum dolar sit amet, adipiscing edit, sed", "notes": "Test",
          "email": "example@gmail.com", "id": 2,
          "mobile_number": "9878631205", "vacc_detail": "No",
          "profilepic": ""
      }
  
  ],
    statisticDetails: {
      "totalPatients": [{
        "month": "March",
        "count": 70
      },
      {
        "month": "April",
        "count": 60
      },
      {
        "month": "May",
        "count": 40
      }
      ],
      "activePatients": [{
        "month": "March",
        "count": 35
      },
      {
        "month": "April",
        "count": 25
      },
      {
        "month": "May",
        "count": 9
      }
      ]
    },
    TotalAndNewLessionCount :    

    {
      "totalLessons": {
          "month": "May",
          "count": 200
      },
      "newLessons": {
          "month": "May",
          "count": 22
      }
    },
    TotalAndNewLessionCountByMonth :
     {
       "may":{
        "totalLessons": {
          "month": "May",
          "count": 200
      },
      "newLessons": {
          "month": "May",
          "count": 22
      }
       },
       "april":{
        "totalLessons": {
          "month": "May",
          "count": 180
      },
      "newLessons": {
          "month": "May",
          "count": 20
      }
       },
       "march":{
        "totalLessons": {
          "month": "May",
          "count": 160
      },
      "newLessons": {
          "month": "May",
          "count": 18
      }
       }
     },

     patientStatistics:  {
      "titleDetails": {
        "titleName": "patient report"
      },
      "reportDetails": [{
          "assessmentName": "perception",
          "totalAttempts": 12,
          "wrongAnswer": 45,
          "correctAnswer":38
      
        },
        {
          "assessmentName": "Listening",
          "totalAttempts": 33,
          "wrongAnswer": 55,
          "correctAnswer": 22
         
        }
      ]
    }
  
};
