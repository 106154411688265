<div style="position: relative;">
  <div class="custom-dropdown-wrapper" id="id-custom-dropdown-wrapper">
    <div *ngIf="showSearchFlag" (click)="openDropDown()" class="custom-dropdown-selected"
      id="id-custom-dropdown-selected">
      <div>
        <span #selectedItemText id="op">{{ selectedRollUpGroup }}</span>
        <i *ngIf="!showSearch" id="down" class="fa fa-chevron-down" aria-hidden="true"></i>
        <i *ngIf="showSearch" id="up" class="fa fa-chevron-up" aria-hidden="true"></i>
      </div>
      <!-- <div *ngIf="!showSearchFlag">
        <input class="searchbar" id="custom-dropdown-filter" type="text" placeholder="search here"
          (keyup)="filterList($event.key, $event.target.value)" />
        <span>
          <i id="search-icon" class="fa fa-search" aria-hidden="true"></i>
        </span>
      </div>       -->
    </div>
    <div *ngIf="!showSearchFlag" class="input-group search-group" #searchbar>
      <input type="text" [value]="selectedRollUpGroup" class="form-control" placeholder="Search BTE Number"
        (keyup)="filterList($event.key, $event.target.value)">
      <div class="input-group-append">
        <button class="btn" type="button">
          <div class="icon-mt-search-grey"></div>
        </button>
      </div>
    </div>

    <div style="width: 12%;">
      <button #searchbutton id="searchbutton" class="btn btn-primary btn-main btn-rounded px-4 btn-search"
        (click)="onButtonClick()">
        <div [ngClass]="showSearchFlag ? 'icon-mt-search-white' : 'icon-mt-expand-white'"></div>
      </button>
    </div>

  </div>
  <div class="custom-dropdown-list-panel" id="id-custom-dropdown-list-panel">
    <div #searchBox *ngIf="showSearch" class="custom-dropdown-search-wrapper">
      <!-- <input id="custom-dropdown-filter" type="text" placeholder="search here"
        (keyup)="filterList($event.key, $event.target.value)" />
      <span>
        <i id="search-icon" class="fa fa-search" aria-hidden="true"></i>
      </span> -->
    </div>

    <div [ngStyle]="{ 'display': showSearch ? 'block' : 'none' }" #listBox class="custom-dropdown-list-items">
      <ul id="custom-dropdown-list-ul">
        <li *ngFor="let data of dataList;let i=index;trackBy: trackByFn" (click)="selectItem(data,i)" id="item_{{i}}"
          class="custom-dropdown-list-item"
          [ngClass]="data.bteSerialNumber == selectedRollUpGroup ? 'highlightBg':null">
          <!-- <span (click)="confirmDelete(i)" class="custom-dropdown-list-item-icon">
            <div [ngClass]="data.checked? 'icon-mt-checkbox-selected':'icon-mt-checkbox'" aria-hidden="true"></div>
          </span> -->
          <span style="margin-left:8px;">{{data.bteSerialNumber}}</span>


        </li>
      </ul>
    </div>
  </div>
</div>