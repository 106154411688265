import {
  Component,
  OnInit,
  Self,
  Optional,
  Input,
  ViewChild,
  ElementRef,
  Output,
  Injectable,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import {
  NgbDateStruct,
  NgbDateParserFormatter,
  NgbDateAdapter,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "-";
  stringMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1]),
        year: parseInt(date[2], 20),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day +
          this.DELIMITER +
          this.stringMonth[date.month - 1] +
          this.DELIMITER +
          date.year
      : "";
  }
}

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() label: string = "";
  @Input() defaultValue: NgbDateStruct;
  @Input() outlined: string = "fullBorder";
  @ViewChild("input") inputRef: ElementRef;
  @Output() onDateSelect = new Subject();
  @Input() minDate: NgbDateStruct;
  @Input() maxDate : NgbDateStruct;

  value: any = "";
  isFocus: boolean = false;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    // console.log(this.minDate);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputBlur = fn;
  }

  onInputFocus() {
    this.isFocus = true;
    this.inputRef.nativeElement.focus();
  }

  onInputBlur() {
    this.isFocus = false;
    if (this.defaultValue) {
      this.isFocus = true;
    }
  }
  onInputChange(e) {
    this.isFocus = true;
    this.onDateSelect.next(this.defaultValue);
  }
}
