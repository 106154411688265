import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-successmessage",
  templateUrl: "./modal-successmessage.component.html",
  styleUrls: ["./modal-successmessage.component.scss"],
})
export class ModalSuccessmessageComponent implements OnInit {
  @Input() message: string;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.activeModal.close();
    }, 1000);
  }
}
