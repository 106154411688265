<div class="icon-mt-close-dark icon " ngbTooltip="Close" tooltipClass="my-custom-class" placement="bottom"
        (click)="onClose()"></div>
<div class="container-header">
    <h5 class="container-title">{{ tileBlockTitle }}</h5>
    <!-- <div class="filter">
        <div class="filter-item">
            <app-modal-comments></app-modal-comments>
            <app-button id="commentsAL" style="margin-right:10px;" [iconClass]="'icon-mt-add'"  [buttonText]="'Comments'" ></app-button>
            <app-button id="exportAL" style="margin-right:10px;" [iconClass]="'icon-mt-export'"  [buttonText]="'Export'" ></app-button>
            <app-button id="closeAL" [iconClass]="'icon-mt-close-white'" [buttonText]="'Close'"
                routerLink="/therapist/preLingual/patientSummary/stats"></app-button>
                 <div class="icon-mt-back-arrow" routerLink="/therapist/preLingual/patientSummary/stats"></div>
                <div class="back-clr">Back</div>
        </div>
    </div> -->
</div>

<!-- <div class="container-body">
    <app-lessons-tile-block [assignedCourses]='assignedCourses' [completedCourses]='completedCourses'
        [pendingCourses]='pendingCourses' [headers]="headerLists"></app-lessons-tile-block>
</div> -->
<div class="card-header-filter" style="display:inline-flex;margin-left: 18px;">
    <h5 class="text">Statistics</h5>
</div>
<div class="card statistics-card p-24 col-lg-12 col-md-12 col-sm-12 plr-0">
    <div class="card-body">
        <div class="mt-3">
            <div class="stats">
                <canvas baseChart *ngIf="statsList" [datasets]="barChartData"
                    [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [colors]="barChartclr"
                    [chartType]="barChartType" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
                <div class="nodata-class" *ngIf="!statsList">No data Found.</div>
            </div>
        </div>
    </div>
</div>