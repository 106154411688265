<div class="card statistics-card p-0">
    <div class="card-body p-24">

        <div class="card-header-filter" style="display:inline-flex; width:100%;">
            <div class="iconDesign">
                <img src="/assets/imgs/100_ic_statistics_circle.svg" />
            </div>
            <!-- <h5 style="margin-left:8px;">{{ title }}</h5> -->
            <h5 *ngIf="lineChartData" style="margin-left:8px; widows: 32px;;">{{ title }}</h5>
            <!-- <h5 *ngIf="!lineChartData" style="margin-left:8px;">No Data Available</h5> -->

            <div class="card-filter d-none d-md-inline-flex">
                <!-- <app-dropdown class="filter mx-2" [inputLists]="xAxisFilter" [selected]="selectedValue"></app-dropdown> -->
                <app-dropdown class="filter mx-2" [inputLists]="monthFilter" [selected]="selectedMonth"></app-dropdown>
            </div>
        </div>

        <div class="mtt-3">
            <app-line-chart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                [legend]="lineChartLegend" [colors]="lineChartColors" (onChartClick)="chartClicked($event)"
                (onChartHover)="chartHovered($event)">
            </app-line-chart>
        </div>
    </div>
</div>