import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { TileBlockComponent } from "./components/tile-block/tile-block.component";
import { SharedModule } from "./shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RestApiService } from "./_services/rest-api/rest-api.service";
import { ChartsModule } from "ng2-charts";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { PatientService } from "./_services/patient/patient.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { LocalStorageService } from "./_services/local-storage/localstorage.service";
import { IconComponent } from "./components/icon/icon.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { InfoDialogComponent } from "./components/info-dialog/info-dialog.component";
import { 
  LocationStrategy,
  HashLocationStrategy,
  TitleCasePipe,
} from "@angular/common";
import { ModalDatepickerComponent } from "./components/modal-datepicker/modal-datepicker.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalSuccessmessageComponent } from "./components/modal-successmessage/modal-successmessage.component";
import { ModalChangepasswordComponent } from "./components/modal-changepassword/modal-changepassword.component";
import { ModalStatisticsComponent } from './components/modal-statistics/modal-statistics.component';
import { DisableCopyCutPasteDirective } from "./login/disable-cut-copy-paste.directive";
// import { PasswordTextfieldComponent } from './components/password-textfield/password-textfield.component';
// import { AdminDefaultCardComponent } from './components/admin-default-card/admin-default-card.component';


export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/app/", suffix: ".json" },
    { prefix: "./assets/i18n/pre-login/", suffix: ".json" },
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TileBlockComponent,
    ConfirmDialogComponent,
    PageNotFoundComponent,
    IconComponent,
    InfoDialogComponent,
    ModalDatepickerComponent,
    LoaderComponent,
    ModalSuccessmessageComponent,
    ModalChangepasswordComponent,
    ModalStatisticsComponent,
    DisableCopyCutPasteDirective,



  ],
  imports: [
    SharedModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    ChartsModule,
    AppRoutingModule,
    NgbCarouselModule,
    NgxSpinnerModule,    
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    RestApiService,
    PatientService,
    LocalStorageService,
    TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
