import { scheduled } from "rxjs";

const constant = {
  patientProgressValue: 50,
  underPerformingValue: 20,
  wellPerformingValue: 80,
  inactivePatientValue: 15,
  averagePerformingValue: 45,

  patientProgressKey: "patientProgressKey",
  underPerformingKey: "underPerformingKey",
  wellPerformingKey: "wellPerformingKey",
  inactivePatientKey: "inactivePatientKey",
  averagePerformingKey: "averagePerformingKey",
  org: "hcl.com",
  emptyString: "",
  flowType: "flowType",
  preLingual: "preLingual",
  postLingual: "postLingual",
  admin: "Admin",
  patientCategory: "patientCategory",
  selectedDevice:"selectedDevice",
  currentSelectedPatient:"currentSelectedPatient",
  selectedTherapistDetails:"selectedTherapistDetails",
  id:"id",
  patientList:"Patient List",
  therapistList:"Therapist List",
  notApplicable:"Not Applicable",
  true:"true",
  false:"false",
  editIcon:"edit-icon",
  deleteIcon:"delete-icon",
  basic:"Basic",
  inValid:"INVALID",
monthFormat:["Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",],
  
  loginScreen: {
    welcome: "welcome",
    therapist: "therapist",
    Therapist: "Therapist",
    admin: "admin",
    userToken: "userToken",
    therapistId: "therapistId",
    adminId: "adminId",
    careId:"careId",
    therapistName: "therapistName",
    rememberMe: "rememberMe",
    credentials: "credentials",
    copywrightText: "- HCL Technologies - All rights reserved",
    copywrightSymbol: "© ",
  },

  welcomeScreen: {
    title: "HCL Suno",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    displayTextpreLingual: "Pre - Lingual",
    displayTextpostLingual: "Post - Lingual",
  },

  genders: {
    male:"Male",
    female:"Female",
    others:"Others"
  },

  deviceDetails: {
    patientimplantSerialnumber:"patientimplantSerialnumber",
    patientspSerialnumber:"patientspSerialnumber"
  },

  message : {
    close:"Are you sure you want to close with out saving?",
    remove:"Are you sure you want to remove this patient?",
    deletetherapist:"Therapist Details Deleted Succesffully",
    removeTherapist:"Are you sure you want to delete this therapist?",
    saveDefaultCourse:"Are you sure you want to save the default course?",
    verifyAllCategories:"please verify all categories",
    issuccessfullyVerified:" is Sucessfully Verified",
    AlreadyVerified:"This category is already Verified",
    SelectDates:"Please Select the Dates",
    scheduledDates:"Please Select the Scheduled Dates",
    deletelessons:"Are you sure you want to delete this assigned lesson details?",
    pwdMsg:"Password Changed Successfully",
    crctPwdEmsg:"Please enter correct password",
    newPwdEmsg:"Password must Start with letter and should contain 8 characters and at least one number, one Uppercase letter and one lowercase letter and one unique character such as !@#$%^&*? etc",
    cnfrmPwdEmsg:"New password and confirm password are not same",
  },
  
  lessonOverview: {
    monthsFilter: "monthsFilter",
    selectedMonth: "selectedMonth",
    all: "ALL",
    selectlesson: "selectedLessonObj",
    selectedTraining: "selectedTrainingsObj",
    courselevel: "courseLevel",
    false: "false",
    courseName: "courseName",
    selectHere: "Select Here",
    description: "therapist/preLingual/lessonList/description",
    dashboard: "/therapist/preLingual/dashboard/stats",
    overview: "therapist/preLingual/lessonList/overview",
    selectTraining: "selectedTrainingsObj",
    training: "/therapist/preLingual/lessonList/training",
    back: "/therapist/preLingual/lessonList/description"
  },

  postlingual: {
    dashboard: "therapist/postLingual/reports/home",
  },

  prelingual: {
    selectedLesson:"selectedLesson",
    selectedLevel:"selectedLevel",
    selectedLessonLevelId:"selectedLessonLevelId",
    selectedGoal:"selectedGoal",
    goalType:'goalType',
    goalNames :[
      "Auditory",
      "Receptive Language",
      "Expressive Language",
      "Speech",
      "Cognition",
      "Others",
    ],
    statLegends :["Number Of Attempts", "Correct", "In Correct"],
    dropdownDateLists:["One Day","One Week", "One Month","Range"],
    Headerslist:[
      "Assigned Lessons",
      "Completed Lessons",
      "Pending Lessons",
    ],
    sortbylesson:"SortBy Lesson",
    SortbyDate:"Date",
    Sortbystatus:"Status",
    emptyDate:"--/---/----",
  },

  adminScreen :{
    adminPatientDetails:"adminPatientDetails",
    selectedDeviceDetails:"selectedDeviceDetails",
    deviceMaintenanceList :"Device Management List",
    deviceMantainance: "deviceMantainance",
    defaultCourse:"DefaultCourse",
    courseDetails:"CourseDetails",
    sortBy : "SortBy",
    implant:"Implant",
    speechProcessor:"Speech Processor",
    status:"Status",
    type:"Type",
    deviceType: "deviceType",
    editDeviceDetails:"editDetails",
    activeTab:"ActiveTab",
    Level:"Level",
    Module:"Module",
  },

  RoutingUrl: {
    preLingualDashboard: "therapist/preLingual/dashboard",
    postLingualDashboard: "therapist/postLingual/dashboard",
    AdminDashboard: "admin/adminPatientDetails",
    adminPatient:"/admin/patient",
    therapistDetails:"/admin/therapistDetails",
    managetherapist:"admin/manageTherapist",
    implantdevice: "/admin/addImplantDevice",
    deviceMaintainance:"/admin/deviceMantainance",
    defaultCourseList:"admin/defaultCourseList",
    lessonList:'admin/lessonlist',
    AddDefaultCourse:"/admin/defaultCourse",
    AddLessonPackagePre:'admin/addpackagepre',
    AdminLessonList:'admin/lessonlist',
    preLingualaddLessons:"therapist/preLingual/patientSummary/addLessons",
    help:"/therapist/setting/help",
    assignedLessonscreenPrelingual:"therapist/preLingual/patientSummary/assignedLesson",
    addLessonScreen:"therapist/preLingual/patientSummary/addLessons",
    goalActivities:"therapist/preLingual/patientSummary/goalActivities",
    lessonGoalPoints:"therapist/preLingual/patientSummary/lessonGoalPoints",
    patientSummaryStats:"therapist/preLingual/patientSummary/stats"

  },

};

export default constant;
