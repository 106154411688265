import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ChartBaseComponent } from "../chart-base/chart-base.component";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent extends ChartBaseComponent implements OnInit {
  // @Input() datasets: any;
  // @Input() labels: any;
  // @Input() options: any;
  // @Input() legend: boolean;
  // @Input() colors: any;

  // @Output() private chartHover = new Subject<any>();
  // @Output() private chartClick = new Subject<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
