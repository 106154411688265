<ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = ''">{{ successMessage }}</ngb-alert>
<div [ngClass]="flowType === 'preLingual' ? 'pd-0' : 'pd-24'">
    <div class="header">
        <h5 class="title m-0 font-regular">{{isEditAction ? 'Edit Patient' : 'Add Patient'}}</h5>
        <div>
            <div *ngIf="!isEditAction" class="icon-mt-search icon1" ngbTooltip="Select Device"
                tooltipClass="my-custom-class" placement="bottom" (click)="onSearchClick()"></div>
            <div class="icon-mt-close-dark icon2" ngbTooltip="Close" tooltipClass="my-custom-class" placement="bottom"
                (click)="onClose()"></div>
        </div>
    </div>

    <div *ngIf="isFormReady"
        style="width:100%;background-color:white;padding:24px; padding-top: 0px;border-radius:8px;">
        <form [formGroup]="addPatientForm" (ngSubmit)="onPatientSave()">
            <!-- (ngSubmit)="onPatientSave()" -->
            <div class="row">

                <div class="col-12 col-md-4 col-lg-4 pt-4">
                    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                        <div style="display:flex;">
                            <div style="flex:1;">
                                <div class="container">

                                    <div class="avatar-upload">
                                        <div class="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                                (change)="uploadFile($event)" />
                                            <label for="imageUpload"></label>
                                        </div>
                                        <div class="avatar-preview" *ngIf="imageUrl == ''">
                                            <div class="icon-mt-user">
                                            </div>

                                        </div>

                                        <div class="avatar-view" *ngIf="imageUrl != ''">
                                            <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <!-- Submit Button -->
                            <!-- <div style="flex:1;align-self: flex-end;">
                                <button style="border:0px;background-color:#fff;font-size: 14px; color: #999999;" 
                                type="submit" (click)="fileInput.click()">Upload Image</button>
                        </div> -->
                        </div>
                    </form>
                </div>

                <div class="col-12 col-md-4 col-lg-4 pt-4">
                    <div>
                        <app-textfield id="firstName" formControlName="patientFirstName"
                            [outlined]="addPatientForm.controls.patientFirstName.touched && addPatientForm.controls.patientFirstName.status == 'INVALID' ? 'errorUnderline':'underline'"
                            [defaultValue]="addPatientForm['controls'].patientFirstName.value" [type]="'text'"
                            [placeholder]="''" [label]="'First Name'" (onFocusTextField)="onFirstname($event)">
                        </app-textfield>
                        <div style="height: 18px;">
                            <div class="errorMsg"
                                *ngIf="addPatientForm.controls.patientFirstName.touched && addPatientForm.controls.patientFirstName.status == 'INVALID'">
                                Please enter first name</div>
                        </div>
                    </div>
                    <div class="pt-4" style="padding-top: 6px;">
                        <app-radio-button [label]="'Gender'" [radioList]="genderList"
                            [defaultValue]="addPatientForm['controls'].patientGender.value"
                            formControlName="patientGender">
                        </app-radio-button>
                    </div>
                </div>

                <div class="col-12 col-md-4 col-lg-4 pt-4 pt-4">
                    <div>
                        <app-textfield id="lastName" [type]="'text'" formControlName="patientLastName"
                            [outlined]="addPatientForm.controls.patientLastName.touched && addPatientForm.controls.patientLastName.status == 'INVALID' ? 'errorUnderline':'underline'"
                            [defaultValue]="addPatientForm['controls'].patientLastName.value" [placeholder]="''"
                            [label]="'Last Name'"></app-textfield>
                        <div style="height: 18px;">
                            <div class="errorMsg"
                                *ngIf="addPatientForm.controls.patientLastName.touched && addPatientForm.controls.patientLastName.status == 'INVALID'">
                                Please enter last name</div>
                        </div>

                    </div>
                    <div class="pt-4" style="padding-top: 6px;">
                        <app-date-picker formControlName="patientDOB"
                            [outlined]="addPatientForm.controls.patientDOB.touched && addPatientForm.controls.patientDOB.status == 'INVALID' ? 'errorUnderline':'underline'"
                            [defaultValue]="addPatientForm['controls'].patientDOB.value" [label]="'Date Of Birth'">
                        </app-date-picker>
                        <div style="height: 18px;">
                            <div class="errorMsg"
                                *ngIf="addPatientForm.controls.patientDOB.touched && addPatientForm.controls.patientDOB.status == 'INVALID'">
                                Please enter date of birth</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <app-textfield id="mobNum" [type]="'text'" formControlName="patientPhone"
                        [outlined]="addPatientForm.controls.patientPhone.touched && addPatientForm.controls.patientPhone.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].patientPhone.value" [placeholder]="''"
                        [label]="'Mobile Number'">
                    </app-textfield>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.patientPhone.touched && addPatientForm.controls.patientPhone.status == 'INVALID'">
                            Please enter valid mobile number</div>
                    </div>

                </div>

                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <app-textfield id="email" [type]="'email'" formControlName="patientEmail"
                        [outlined]="addPatientForm.controls.patientEmail.touched && addPatientForm.controls.patientEmail.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].patientEmail.value" [placeholder]="''"
                        [label]="'E-mail'" email>
                    </app-textfield>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.patientEmail.touched && addPatientForm.controls.patientEmail.status == 'INVALID'">
                            Please enter valid email id</div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <app-textfield id="bteSerialNumber" [type]="'text'" formControlName="bteSerialNumber"
                        [disabled]="true"
                        [outlined]="addPatientForm.controls.bteSerialNumber.touched && addPatientForm.controls.bteSerialNumber.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].bteSerialNumber.value" [placeholder]="''"
                        [label]="'BTE Serial Number'">
                    </app-textfield>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.bteSerialNumber.touched && addPatientForm.controls.bteSerialNumber.status == 'INVALID'">
                            Please enter valid BTE Serial Number
                        </div>
                    </div>

                </div>

            </div>



            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <!-- <label class="textLabel" style="margin-bottom:12px;">Vaccinated for Meningitis</label>
                <div>
                    <app-dropdown [outlined]="false" id="vaccineDropdown" formControlName="isVaccinated"
                        [inputLists]="dropdownLists" [selected]="addPatientForm['controls'].isVaccinated.value"
                        (onDropdownSelect)="onTileDropdown($event)"></app-dropdown>
                </div> -->

                    <app-textfield id="implantSerialNumber" [type]="'text'" formControlName="implantSerialNumber"
                        [disabled]="true"
                        [outlined]="addPatientForm.controls.implantSerialNumber.touched && addPatientForm.controls.implantSerialNumber.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].implantSerialNumber.value" [placeholder]="''"
                        [label]="'Implant Serial Number'">
                    </app-textfield>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.implantSerialNumber.touched && addPatientForm.controls.implantSerialNumber.status == 'INVALID'">
                            Please enter valid Implant Serial Number
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <app-date-picker formControlName="patientImplantDate"
                        [outlined]="addPatientForm.controls.patientImplantDate.touched && addPatientForm.controls.patientImplantDate.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].patientImplantDate.value" [label]="'Implant Date'">
                    </app-date-picker>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.patientImplantDate.touched && addPatientForm.controls.patientImplantDate.status == 'INVALID'">
                            Please enter Implant Date</div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 pt-4" style="padding-top: 6px;">
                    <app-date-picker formControlName="switchOnDate"
                        [outlined]="addPatientForm.controls.switchOnDate.touched && addPatientForm.controls.switchOnDate.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="addPatientForm['controls'].switchOnDate.value" [label]="'Switch-On Date'">
                    </app-date-picker>
                    <div style="height: 18px;">
                        <div class="errorMsg"
                            *ngIf="addPatientForm.controls.switchOnDate.touched && addPatientForm.controls.switchOnDate.status == 'INVALID'">
                            Please enter Switch-On Date</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 col-lg-6 pt-4 row=2" style="padding-top: 6px;">
                    <app-text-area id="avd" [label]="'Additional Vaccination Notes'" formControlName="avd"
                        [defaultValue]="addPatientForm['controls'].avd.value"></app-text-area>
                </div>
                <div class="col-12 col-md-6 col-lg-6 pt-4 row=2" style="padding-top: 6px;">
                    <app-text-area id="patSumm" [label]="'Notes'" formControlName="notes"
                        [defaultValue]="addPatientForm['controls'].notes.value"></app-text-area>
                </div>
            </div>


            <div class="footer">
                <app-button id="saveBtn" style="margin-left:15px;"
                    [disabled]="!addPatientForm.valid || !addPatientForm.dirty " [buttonText]="'Save'"
                    (click)="onAddButtonClick()"></app-button>
                <!-- <app-button id="resetBtn" [buttonText]="'Reset'" (click)="onResetButtonClick()"></app-button> -->
            </div>
        </form>
    </div>
</div>