import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Self,
  Optional,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Subject } from "rxjs";
import { DashboardService } from "src/app/_services/Dashboard/dashboard.service";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent implements OnInit {
  @Input() radioList: any[];
  @Input() disabled: boolean;
  @Input() defaultValue: string = "";
  @Input() label: string = "";
  @ViewChild("input") inputRef: ElementRef;
  @Output() radioSelect = new Subject();

  value: any = "";
  isFocus: boolean = false;
  inputdata: string = "";

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    private dashboardService: DashboardService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.setRadioGroupValue(this.defaultValue);
    this.dashboardService.genderSubject.subscribe((value) => {
      this.setRadioGroupValue(value);
    });
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  setRadioGroupValue(value: any): void {
    for (let i = 0; i < this.radioList.length; i++) {
      if (this.radioList[i].label == value) {
        this.radioList[i].checked = true;
      } else {
        this.radioList[i].checked = false;
      }
    }
  }

  onClick(label) {
    this.isFocus = true;
    this.setRadioGroupValue(label);
    this.radioSelect.next(label);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onClick = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputBlur = fn;
  }

  onInputFocus() {
    this.isFocus = true;
    this.inputRef.nativeElement.focus();
  }

  onInputBlur() {
    this.isFocus = false;
  }
}
