<div (focus)="onInputFocus()" class="dropdown">
    <div class="placeHolder" *ngIf="selectedVal">Select Here</div>
    <label class="dropdownLabel">
            <select [id]="dropdownId" class="dropdownSelect" 
                    [ngClass]="outlined ? 'form-rounded' : 'form-underline'" #input [(ngModel)]="selected"
                    name="customDropDown"  (ngModelChange)="onOptionsSelected($event)"
                    (focus)="onInputFocus()" (blur)="onInputBlur()"  [disabled]="disabled">
                    <option *ngFor="let list of inputLists" [value]="list">{{list}}
                    </option>
                    <!-- <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option> -->
            </select>
    </label>
</div>
