import { Component, OnInit, Input } from "@angular/core";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Label, Color } from "ng2-charts";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";

@Component({
  selector: "app-satistics-card",
  templateUrl: "./satistics-card.component.html",
  styleUrls: ["./satistics-card.component.scss"],
})
export class SatisticsCardComponent implements OnInit {
  @Input() lineChartData: ChartDataSets[];
  @Input() lineChartLabels: Label[];
  @Input() title: string = "Statistics";
  xAxisFilter: any[] = ["Daily", "This Week", "This Month"];
  monthFilter: any[] = ["July", "June", "May"];
  selectedValue = this.xAxisFilter[2];
  selectedMonth = this.monthFilter[0];
  statisticsList: any;
  // lineChartData: ChartDataSets[];
  // lineChartLabels: Label[];
  lineChartOptions: ChartOptions = {
    responsive: true,
    // legendCallback: (chart: Chart): string => {
    //   return 'Label';
    // },
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      position: "top",
      align: "end",
      fullWidth: true,
      labels: {
        usePointStyle: true,
        boxWidth: 8,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  lineChartColors: Color[] = [
    {
      borderColor: "transparent",
      backgroundColor: "#8C6CB255",
    },
    {
      borderColor: "transparent",
      backgroundColor: "#F15E7E55",
    },
  ];
  lineChartLegend = true;

  constructor(private api: RestApiService) {}

  ngOnInit(): void {
    // this.lineChartData = this.data;
    // this.lineChartLabels = this.label;
  }

  public chartClicked(event: any) {
    //console.log(event);
  }

  public chartHovered($event) {
    // //console.log($event);
  }
}
