import { Injectable } from "@angular/core";
import { EncrDecrService } from "../encr-decr.service";
@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  localStorage: Storage;
  constructor(
    private EncrDecr: EncrDecrService
  ) {
    this.localStorage = window.localStorage;
  }
  get(key: string): any {
    if (this.isLocalStorageSupported) {
if (this.localStorage.getItem(key) != null){
  let json =JSON.parse(this.localStorage.getItem(key));
  var decryptedJson = this.EncrDecr.get(json);

  return JSON.parse(decryptedJson);
} else {
  return null;
}
     
    }
    return null;
  }


  // let json = this.localStorage.get(constant.loginScreen.credentials);
  // var decryptedJson = this.EncrDecr.get(json);
  //   console.log("decrypted json", decryptedJson);

    
  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      var encryptedJson = this.EncrDecr.set(JSON.stringify(value));
      this.localStorage.setItem(key, JSON.stringify(encryptedJson));
      return true;
    }
    return false;
  }

 ;


  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  clear(): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.clear();
      return true;
    }
    return false;
  }
  //Old browser doesn't support web storage eg:IE7
  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
