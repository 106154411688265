<div>
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <!-- <p>{{message}}</p> -->
    <div style="font-size:14px; width: auto;">{{message}}</div>
  </div>
  <div class="modal-footer">
    <app-button id="infoModelButton" [buttonText]="'Ok'" (btnClick)="activeModal.close()"></app-button>
  </div>
</div>