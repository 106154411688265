import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { UiUtilService } from "../_services/ui-utils/ui-util.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  cnfrmDialog:any;
  constructor(private router: Router,private uiUtils: UiUtilService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((ErrorResponse: HttpErrorResponse) => {

      //   function isJson(str) {
      //     try {
      //         JSON.parse(str);
      //     } catch (e) {
      //         return false;
      //     }
      //     return true;
      // }

        if(ErrorResponse.error.message){
          const cnfrmDialog = this.uiUtils.showInfoDialog({
            message: ErrorResponse.error.message,
            title: "Error",
          });
        }
        else if (ErrorResponse.status === 404) {
          const cnfrmDialog = this.uiUtils.showInfoDialog({
            message: ErrorResponse.status+"API not found",
            title: "Error",
          });
        }
        else if (ErrorResponse.status === 500) {
          const cnfrmDialog = this.uiUtils.showInfoDialog({
            message: ErrorResponse.status+"Internal server error",
            title: "Error",
          });
        }
        else if(ErrorResponse.status===0){
          const cnfrmDialog = this.uiUtils.showInfoDialog({
            message: "Service unavailable, Please contact administrator",
            title: "Error",
          });
        }
        console.log(ErrorResponse);

        // if(ErrorResponse.error instanceof ErrorEvent){
        // //Client side error
        // this.cnfrmDialog = this.uiUtils.showInfoDialog({
        //   message: ErrorResponse.error.message,
        //   title: "Error",
        // })
        // } else {
        //   //Server side error
        //   this.cnfrmDialog = this.uiUtils.showInfoDialog({
        //               message: ErrorResponse.error.message,
        //               title: "Error",
        //             });
        // }
        // } else if (ErrorResponse.error.message) {
        //   if(ErrorResponse.error.message){
        //         const cnfrmDialog = this.uiUtils.showInfoDialog({
        //           message: ErrorResponse.error.message,
        //           title: "Error",
        //         });
        //       }
        // } else if (ErrorResponse.status === 404) {
        //   const cnfrmDialog = this.uiUtils.showInfoDialog({
        //     message: ErrorResponse.status+"API not found",
        //     title: "Error",
        //   });
        // }
        // else if (ErrorResponse.status === 500) {
        //   const cnfrmDialog = this.uiUtils.showInfoDialog({
        //     message: ErrorResponse.status+"Internal server error",
        //     title: "Error",
        //   });
        // }
        // else if(ErrorResponse.status===0){
        //   const cnfrmDialog = this.uiUtils.showInfoDialog({
        //     message: "Please contact administrator",
        //     title: "Error",
        //   });
        // }
        console.log(ErrorResponse);
        return throwError(ErrorResponse.error.message);
      })
    );
  }
}
