import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  
  currentPlayingVideo: HTMLVideoElement;
  currentPlayingAudio: HTMLAudioElement;
  @Input() vedioSrc: any;
  @Input() exerciceTitle: any;

  @Output() public linkedClicked = new EventEmitter<String>();
// selected: boolean = true;

@Input() selected:boolean;
@Input() selectedall:boolean

  constructor() {
    
   }
  
  ngOnInit(): void {
 
  }

  // ngAfterViewInit(): void{
  //   const thumbnailcard = document.getElementsByName('thumb') as unknown as HTMLInputElement;
  //   for(var i=0 ,j=0, n=(<any>thumbnailcard).length; i<n && j<this.vedioSrc.length ;i++ ,j++) {
  //     // thumbnailcard[i].style.background="url('/assets/imgs/washing_mechine.png')";
  //     let p=this.vedioSrc[j].Thumbnailurl;
  //     thumbnailcard[i].style.background='url(' + p + ')';
  //     // console.log(o)
  //   }
  // }
  
//if we click tick mark on vedio 
  onLinkedClicked(list, event:any) {
    let elementii = event.target || event.srcElement || event.currentTarget;
    let p=event.srcElement.parentElement.parentElement.parentElement.children[0];
    // console.log(p)
    // p.checked=true;
    if(list.isAssigned==false||list.isDefault==false){
      p.checked=true;
    }
   
}
  
// toggleVideo(videooo){
//   if (videooo.paused) {
//     videooo.play();
// } else {
//   videooo.pause();
// }
// }

playAudio(Audioo,data){
  if(Audioo.paused){
    if (this.currentPlayingAudio === undefined) {
      this.currentPlayingAudio = Audioo;
      this.currentPlayingAudio.play();
  } else {
  // if the user plays a new video, pause the last
  // one and play the new one
      if (Audioo !== this.currentPlayingVideo) {
          this.currentPlayingAudio.pause();
          this.currentPlayingAudio = Audioo;
          this.currentPlayingAudio.play();
      }
      else if(Audioo === this.currentPlayingAudio){
        Audioo.play()
      }
  }
  }
  else{
    Audioo.pause()
  }
}



toggleVideo(videooo, data){
  // videooo.preventDefault();
  if(videooo.paused){

    videooo.src=data.src
    if (this.currentPlayingVideo === undefined) {
      this.currentPlayingVideo = videooo;
      this.currentPlayingVideo.play();
  } else {
  // if the user plays a new video, pause the last
  // one and play the new one
      if (videooo !== this.currentPlayingVideo) {
          this.currentPlayingVideo.pause();
          this.currentPlayingVideo = videooo;
          this.currentPlayingVideo.play();
      }
      else if(videooo === this.currentPlayingVideo){
        videooo.play()
      }
  }
  }
  else{
    videooo.pause()
  }
 
}

enlargeVideo(videooo){
  if (videooo.requestFullscreen) {
    videooo.requestFullscreen();
} 
}

toggleFullScreen(videooo) {
  if (!document.exitFullscreen) {
    videooo.requestFullscreen();
      }
  else {
    videooo.exitFullscreen();
      }
  
    }
}
