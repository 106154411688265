<div class="topnav">
  <div style="flex:4" id="navFirstDiv">
    <a href="#home" class="userDB" style="width:120px;padding-left:24px;padding-right:24px;">Rehab</a>
    <a href="#about">
      <div id="userIcon" style="display:flex;padding-left:24px;">
        <i class="fa fa-user-circle"> </i>
        <div class='userDB' style="margin-left:24px; padding-right:8px;">User Dashboard</div>
      </div>
    </a>
    <a href="#contact" class='userDB' style="padding-left:8px;">555555</a>
  </div>
  <div style="flex:3;display:flex;">
    <div style="width:68%;">
      <app-search-bar id="searchBar"></app-search-bar>
    </div>
    <div class="plusIconDiv" (click)="onIconClick($event)"><a class="fa fa-plus"> </a></div>
    <div style="margin-left:24px;padding-top:16px;"><a class="fa fa-bell"> </a></div>
  </div>
</div>