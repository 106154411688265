import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { PatientService } from "src/app/_services/patient/patient.service";
import { TitleCasePipe } from "@angular/common";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";

export enum ActionItem {
  DELETE,
  EDIT,
  VIEW,
}

@Component({
  selector: "app-patient-list-table",
  templateUrl: "./patient-list-table.component.html",
  styleUrls: ["./patient-list-table.component.scss"],
})
export class PatientListTableComponent implements OnInit {
  @Input() patients: any[];

  @Output() actionItemClick: Subject<any> = new Subject();
  patientDetails: any;
  flowType: any;
  courseLevelTitle: string;
  LessonProgress: string;

  constructor(
    private router: Router,
    private api: RestApiService,
    private patientService: PatientService,
    private titleCase: TitleCasePipe,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.flowType = this.localStorage.get("flowType");
    
  }

  onActionItemClick(data: any, action: ActionItem): void {
    this.actionItemClick.next({ data, action });
  }

  onDelete(item: any): void {
    if(this.flowType === 'preLingual'){
      this.onActionItemClick(item, ActionItem.DELETE);      
    } else {
      this.onActionItemClick(item, ActionItem.DELETE);      
    }
  }

  onEdit(item: any): void {
    this.onActionItemClick(item, ActionItem.EDIT);
  }

  onView(item: any): void {
    this.onActionItemClick(item, ActionItem.VIEW);
  }

  isStatusAboveLimit(item: any): boolean {
    return item.completedCourses >= 2;
  }
  
  getCompletedProgress(item: any): number {
    return (item.completedCourses / item.totalCourses) * 100;
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }
  onMouseOver(courseLevel,id){
    let p=document.getElementById(id);
    let cell ="td"+id;
    let col = document.getElementById(cell)
    console.log(col)
    if(courseLevel =="Basic") { 
      p.style.backgroundColor="#d8e5fa";
      col.style.color ='#19191e';
    }
    else if(courseLevel =="Intermediate"){
      p.style.backgroundColor="#dfedff";
      col.style.color ='#19191e';
    }
    else if(courseLevel =="Advanced"){
      p.style.backgroundColor="#ecf6ff";
      col.style.color ='#19191e';
    }
    else{
    }
  }
  onmouseout(courseLevel,id){

    let p=document.getElementById(id)
    let cell ="td"+id
    let col = document.getElementById(cell)
    if(courseLevel =="Basic") {
      p.style.backgroundColor="white";
      col.style.color ='#0f5fdc';
    }
    else if(courseLevel =="Intermediate"){
      p.style.backgroundColor="white";
      col.style.color ='#3c91ff';
    }
    else if(courseLevel =="Advanced"){
      p.style.backgroundColor="white";
      col.style.color ='#8cc8fa';
    }
    else{

    }
  }
}
