
<div class="card-wrapper">
    <div  class="inner-card"  *ngFor="let list of vedioSrc; let i = index">
      <div>
        <div class="contentDiv" >
            <div>
                <div  class="imgDiv" name="thumb" style="background: url({{list.Thumbnailurl}})">
                    <input class="checkbox" type="checkbox" name="default_Check" >
                    <button *ngIf="list.isDefault==true" class="assigned icon-mt-selected" title="already default" tooltipClass="my-custom-class" placement="top"></button>
                    <ng-template *ngIf="list.src; then displayVideo; else displayAudio"></ng-template>
                    <ng-template #displayVideo>
                        <vg-player class="vgplayer"><video  class="vid" width="100%" height="100%" #videoObject controlsList="nodownload" oncontextmenu = "return false;"
                        [src]="list.src" type="video/mp4"></video>
                        </vg-player>
            
                        <div class="innerbox" id="disp">
                        <div class="card-items circle "><button (click)="enlargeVideo(videoObject)" class="icon-mt-enlarge innerbtn anim"></button></div>
                        <div class="card-items circle "><button  (click)="toggleVideo(videoObject)"  [ngClass]="videoObject.paused ?'icon-mt-play innerbtn anim':'icon-mt-pause innerbtn anim'"></button></div>
                        <div  class="card-items circle "><button name="pwd" id=questions{{i}} (click)="onLinkClicked(list, $event)" Class="icon-mt-select innerbtn anim" [disabled]="list.isDefault==true"></button></div>
            
                        </div>
                    </ng-template>
            
                    <ng-template #displayAudio>
                        <audio class="aud" controls [src]="list.audiosrc" controlsList="nodownload" oncontextmenu = "return false;" ></audio>
                        <div class="inneraudiobox" id="disp">
                            <div  class="card-items circle "><button name="pwd" id=questions{{i}} (click)="onLinkClicked(list, $event)" Class="icon-mt-select innerbtn anim" [disabled]="list.isDefault==true"></button></div>
                        </div>
                    </ng-template>
                </div>
            </div >
            <div>
            <div class="text">{{list.exerciseName}}</div>
            </div>
      </div>
    </div>
   </div>
  </div>