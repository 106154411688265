import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { MOCK_DB } from "src/app/_utils/mockDB";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { endPoints } from "../../_utils/Api";
import { LocalStorageService } from "../local-storage/localstorage.service";
import { EncrDecrService } from "../encr-decr.service";

@Injectable({
  providedIn: "root",
})
export class RestApiService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response" as "response",
  };
  patientDetail: any;
  therapistId: any;
  token: any;

  constructor(
    private http: HttpClient,
    private EncrDecr: EncrDecrService,
    private localStorage: LocalStorageService,
  ) {
    this.therapistId = this.localStorage.get("therapistId");
  }

  // Login:
  // login(requestBody): Observable<any> {
  //   let requestURL = endPoints.api.login;
  //   const headers = this.getRequestHeaders("urlencoded");
  //   const options: object = { headers, responseType: "text" };
  //   return this.http.post<any>(requestURL, requestBody, options);
  // }
  logout(requestBody): Observable<any> {
    let requestURL = endPoints.api.logout;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers};
    return this.http.post<any>(requestURL, requestBody, options);
  }

  login(requestBody): Observable<any> {
    let requestURL = endPoints.api.loginForNewParamer;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    //  var encryptedJson = this.EncrDecr.set(JSON.stringify(requestBody));
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Health API
  healthcheckapi(requestBody): Observable<any> {
    let requestURL = endPoints.api.healthcheckapi;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    // const options: object = { headers, responseType: "text" };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Forgot Password
  forgotPassword(requestBody): Observable<any> {
    let requestURL = endPoints.api.forgotPassword;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  //Change Password
  changePassword(requestBody): Observable<any> {
    let requestURL = endPoints.api.changePassword;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  //get Therapist Data
  // getTherapist(requestBody): Observable<any> {
  //   let requestURL = endPoints.api.getTherapist;
  //   const headers = this.getRequestHeaders("");
  //   const options: object = { headers: headers };
  //   return this.http.post<any>(requestURL, requestBody, options);
  // }

  getTherapistDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.therapistList;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  fetchActiveTherapist(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchActiveTherapist;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  

  //  Dashboard API:
  getAssignedLessons(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.assignedLessons;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getexerciseOverviewCount(): Observable<any> {
    let requestURL = endPoints.api.dashboard.exerciseOverviewCount;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }


  getPatientList(requestBody): Observable<any> {
    let requestURL = endPoints.api.fetchPatientDetailsByImplantId;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }


  getTotalAndNewLessionCount(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.fetchCourseCountByMonth;
    const headers = this.getRequestHeaders("");
    // const options: object = { headers, responseType: "text" as "json" };
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getExerciseOverviewCount(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.exerciseOverviewCount;
    const headers = this.getRequestHeaders("");
    // const options: object = { headers, responseType: "text" as "json" };
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getlessonDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.lessonDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getPatientPerformance(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.patientPerformance;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getCoureLevel(): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseLevel
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }

  getCourseModule(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseModule
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getCourseCategory(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseCategory
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getcourseExercise(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseExercise
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  assignCourseToPatient(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.assignCourseToPatient
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  assignDefaultCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.assignDefaultCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  addGoals(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.addGoals;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  // getTherapistDetailsById(requestBody): Observable<any> {
  //   let requestURL = endPoints.api.getTherapistDetails;
  //   const headers = this.getRequestHeaders("");
  //   const options: object = { headers: headers };
  //   const requestParams = requestURL+"/"+requestBody
  //   return this.http.post<any>(requestParams, options);
  // }
  getTherapistDetailsById(requestBody): Observable<any> {
    let requestURL = endPoints.api.getTherapistDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getAdminDetailsById(requestBody): Observable<any> {
    let requestURL = endPoints.api.getAdminDetails;
    let requestData = requestURL+requestBody;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestData, options);
  }

  getPatientDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.patientDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    // const options: object = { headers, responseType: "text" as "json" };
    return this.http.post<any>(requestURL, requestBody, options);
  }


  // assignDefaultCourse(requestBody): Observable<any> {
  //   let requestURL =endPoints.api.postLingual.courseContent.assignDefaultCourse;
  //   const headers = this.getRequestHeaders("");
  //   const options: object = { headers: headers };
  //   return this.http.post<any>(requestURL,{requestBody ,options });
  // }

  getcoursecategory(requestBody): Observable<any> {
    let requestURL = endPoints.api.coursecategory
    const headers = this.getRequestHeaders("");
    const params = requestBody;
 return this.http.get<any>(requestURL,{params ,headers });
  }
  addDefaultCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.addDefaultCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    // const options: object = { headers, responseType: "text" as "json" };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getPatients(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.patientList;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  deletePatient(requestBody):Observable<any> { 
    let requestURL = endPoints.api.dashboard.deletepatient;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  deletePatientDetails(requestBody) {
    let requestURL = endPoints.api.admin.deletePatientDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }


  createNewPatient(patientDetails: any): Observable<any> {
    let requestURL = endPoints.api.dashboard.savepatient;
    let requestBody = patientDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  updatePatientDetails(patientDetails: any): Observable<any> {
    let requestURL = endPoints.api.dashboard.updatepatient;
    let requestBody = patientDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Patient Home
  getindividualAssignedCourseDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.patientHome.individualAssignedCourseDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getindividualPatientSummary(requestBody): Observable<any> {
    let requestURL = endPoints.api.patientHome.individualPatientSummary;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getDeletedAssignedCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.patientHome.deleteCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Assigned LessonScreen
  getcourseStatistics(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignedLesson.courseStatistics;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // AssignLesson Screen
  getcategories(): Observable<any> {
    let requestURL = endPoints.api.assignLesson.categories;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }

  goalCategory(): Observable<any> {
    let requestURL = endPoints.api.assignLesson.goalCategory;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }

  getDisplayGoalDetials(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignLesson.displayGoalDetials;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getActivityDetials(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignLesson.activityDetials;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getLessonDetailList(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignLesson.lessonDetailList;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getSearchLessonDetailList(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignLesson.searchCoursesName;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getAssignedCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.assignLesson.assignCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Lesson Overview:
  getLessonsList(requestBody): Observable<any> {
    let requestURL = endPoints.api.lessonOverView;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }


  getCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.getCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  // Reports:
  fetchReports(requestBody): Observable<any> {
    let requestURL = endPoints.api.reports;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  // Admin
  // SignUp Therapist:
  signuptherapist(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.signuptherapist;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getTherapistList(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.therapistList;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getLessonList(): Observable<any> {
    let requestURL = endPoints.api.admin.LessonList
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }
  updateTherapist(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.updateTherapist;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  deleteTherapist(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.deleteTherapist;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  saveDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.saveDeviceDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  deleteDevice(requestBody): Observable<any> {
    let requestURL = endPoints.api.dashboard.deletepatient;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  fetchImplantDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchImplantDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody,options);
  }
  fetchBteDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchBteDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody,options);
  }
  fetchPatients(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchPatient;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  fetchImplantSerial(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchImplantSerial;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }

  fetchSpeechSerial(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.fetchSpeechSerial;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  
  saveImplantDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.saveImplantDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody,options);
  }
  saveBteDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.saveBteDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  savePatientDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.savePatientDetail;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  signupPatient(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.signuppatient;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }

  updateImplantDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.UpdateImplantDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  updateBteDeviceDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.updateBteDevice;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  updatePatient(requestBody): Observable<any> {
    let requestURL = endPoints.api.admin.updatePatientDetail;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL,requestBody, options);
  }
  
  fetchDeviceList(): Observable<any> {
    let requestURL = endPoints.api.admin.fetchDeviceList;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }
  fetchCourseDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody,options);
  }

  fetchAssignedCourse(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchAssignedCourse;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody,options);
  }

  getCourseContent(requestBody): Observable<any> {
    let requestURL = endPoints.api.courseContent;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  // individualPatientReport
  getPatientLevel(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.individualPatientReport.level;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getPatientModule(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.individualPatientReport.modules;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getPatientCategory(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.individualPatientReport.category;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getPatientExercise(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.individualPatientReport.exercise;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  getCourseList(): Observable<any> {
    let requestURL = endPoints.api.postLingual.courseContent.fetchCourseList
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, options);
  }
  getPatientPostLingualReport(requestBody): Observable<any> {
    let requestURL =
      endPoints.api.postLingual.individualPatientReport.postlingual;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  // getPatientPostLingualReportDummy(requestBody): Observable<any> {
  //   let requestURL = "https://mymedtechapp.com:8081/api/reports/postlingual"
  //   const headers = this.getRequestHeaders("");
  //   const options: object = { headers: headers };
  //   return this.http.post<any>(requestURL, requestBody, options);
  // }
  fetchPostLinguaPatientList(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.fetchPatients;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }
  savePostLingualPatientDetails(requestBody): Observable<any> {
    let requestURL = endPoints.api.postLingual.savePatientDetails;
    const headers = this.getRequestHeaders("");
    const options: object = { headers: headers };
    return this.http.post<any>(requestURL, requestBody, options);
  }

  getNotifications(): Observable<any> {
    return of(MOCK_DB.notifications);
  }

  // Set Header for API Request
  private getRequestHeaders(contentType?: string): HttpHeaders {
    let headers = new HttpHeaders();
    if (contentType === "urlencoded") {
      headers = headers.set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
    } else if (contentType === "textplain") {
      headers = headers.set("Content-Type", "text/plain").set("Accept", "*/*");
    } else {
      headers = headers
        .set("Content-Type", "application/json")
        .set("Accept", "*/*");
    }
    return headers;
  }

  httpget(endpointUrl: string): Observable<any> {
    return this.http.get(endpointUrl);
  }
}
