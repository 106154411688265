import { environment } from "../../environments/environment";

const baseUrl = environment.apiUrl;
const loginBaseUrl = environment.loginApiUrl;

export const endPoints = {
  baseUrl,
  api: {
    //Login AP
    loginForNewParamer: loginBaseUrl + "api/public/login",
    login: loginBaseUrl + "security-web/json/security/login",
    logout: loginBaseUrl + "api/public/logout",
    fetchPatientDetailsByImplantId:
      baseUrl + "api/patient/fetchPatientDetailsByImplantId",
    healthcheckapi: baseUrl + "api/patient/health",
    //getTherapist: baseUrl + "api/therapist/getTherapist",
    getTherapistDetails: baseUrl + "api/therapist/getById",
    getAdminDetails: baseUrl + "api/admin/getAdminId",

    // Forgot Password
    forgotPassword: loginBaseUrl + "api/public/forgotpassword",
    changePassword: loginBaseUrl + "api/public/changepassword",
    //Dasnboard API:
    dashboard: {
      assignedLessons: baseUrl + "api/dashboard/patientProgress",
      fetchCourseCountByMonth: baseUrl + "api/dashboard/lessonOverview",
      lessonDetails: baseUrl + "api/dashboard/assignedLessonDetails",
      patientPerformance: baseUrl + "api/dashboard/patientPerformance",
      patientDetails: baseUrl + "api/dashboard/patientDetails",
      patientList: baseUrl + "api/dashboard/patientList",
      deletepatient: baseUrl + "api/admin/patient/unassignpatient",
      savepatient: baseUrl + "api/patient/savePatient",
      updatepatient: baseUrl + "api/patient/updatePatient",
      exerciseOverviewCount: baseUrl + "api/postlingual/exerciseOverview",
    },
    patientHome: {
      individualAssignedCourseDetails: baseUrl + "api/course/assignedCourse",
      individualPatientSummary:
        baseUrl + "api/course/fetchCourseDetailsByPatient",
      deleteCourse: baseUrl + "api/course/deleteCourse",
    },
    assignedLesson: {
      courseStatistics: baseUrl + "api/reports/patients/courseStatistics",
    },

    assignLesson: {
      categories: baseUrl + "api/coursecategory/categories",
      displayGoalDetials: baseUrl + "api/coursecontent/getGoal",
      activityDetials: baseUrl + "api/coursecontent/getActivity",
      lessonDetailList: baseUrl + "api/coursecontent/getAllLessonList",
      searchCoursesName: baseUrl + "api/coursecontent/searchCoursesName",
      assignCourse: baseUrl + "api/course/assignCourseToPatient",
      goalCategory: baseUrl + "api/coursecategory/goalcategory",
    },
    postLingual: {
      fetchPatients: baseUrl + "api/admin/patient/listAllById",
      savePatientDetails:
        baseUrl + "api/patient/postlingual/savePatientDetails",
      dashboard: {
        assignedLessons: baseUrl + "api/dashboard/patientProgress",
        fetchCourseCountByMonth: baseUrl + "api/dashboard/lessonOverview",
        lessonDetails: baseUrl + "api/dashboard/assignedLessonDetails",
        patientPerformance: baseUrl + "api/dashboard/patientPerformance",
        patientDetails: baseUrl + "api/dashboard/patientDetails",
        patientList: baseUrl + "api/dashboard/patientList",
        deletepatient: baseUrl + "api/patient/deletepatient",
        savepatient: baseUrl + "api/patient/savePatient",
        updatepatient: baseUrl + "api/patient/updatePatient",
      },

      individualPatientReport: {
        level: baseUrl + "api/postlingual/reports/level",
        modules: baseUrl + "api/postlingual/reports/modules",
        category: baseUrl + "api/postlingual/reports/category",
        exercise: baseUrl + "api/postlingual/reports/exercise",
        postlingual: baseUrl + "api/reports/postlingual",
      },

      courseContent: {
        fetchCourseLevel: baseUrl + "api/postlingual/list/courselevel",
        fetchCourseModule: baseUrl + "api/postlingual/list/coursemodule",
        fetchCourseCategory: baseUrl + "api/postlingual/assigncoursebymodule",
        fetchCourseExercise: baseUrl + "api/postlingual/list/courseexercise",
        fetchCourseList: baseUrl + "api/postlingual/courseoverview/list",
        assignCourseToPatient: baseUrl + "api/postlingual/assigncourse",
        fetchCourseDetails: baseUrl + "api/postlingual/list/coursedetails",
        fetchAssignedCourse : baseUrl + "api/postlingual/list/assignedcourse",
        assignDefaultCourse: baseUrl + "api/postlingual/add/default",
      },
    },
    admin: {
      createCourse:baseUrl + "api/contentpackage/contentDetails/create",
      LessonList:baseUrl + "api/contentpackage/contentDetails/list",
      signuptherapist: loginBaseUrl + "api/public/signuptherapist",
      // therapistList: baseUrl + "api/therapist/fetchAllTherapist",
      therapistList: baseUrl + "api/therapist/listAll",

      // activeTherapist: baseUrl + "api/therapist/fetchActiveTherapist",
      // saveTherapist: baseUrl + "/api/therapist/saveTherapist",
      saveTherapist: baseUrl + "/api/therapist/create",
      // updateTherapist: baseUrl + "api/therapist/updateTherapist",
      updateTherapist: baseUrl + "api/therapist/update",
      // deleteTherapist: baseUrl + "api/therapist/deleteTherapist",
      deleteTherapist: baseUrl + "api/therapist/update",

      saveDeviceDetails: baseUrl + "api/device/saveDeviceDetails",
      deleteDevice: baseUrl + "api/device/deleteDevice",
      // fetchImplantDevice: baseUrl +"api/device/fetchImplants",
      fetchImplantDevice: baseUrl + "api/admin/device/implant/list",
      // saveImplantDevice: baseUrl + "api/device/saveImplantDetails",
      saveImplantDevice: baseUrl + "api/admin/device/implant/create",
      // UpdateImplantDevice: baseUrl + "api/device/updateImplant",
      UpdateImplantDevice: baseUrl + "api/admin/device/implant/update",

      fetchDeviceList: baseUrl + "api/device/fetchDeviceDetails",
      // fetchBteDevice: baseUrl + "api/device/fetchSpeechprocessor",
      fetchBteDevice: baseUrl + "api/admin/device/speechprocessor/list",
      // saveBteDevice: baseUrl + "api/device/saveSpeechprocessorDetails",
      saveBteDevice: baseUrl + "api/admin/device/speechprocessor/create",
      // updateBteDevice: baseUrl + "api/device/updateSpeechprocessor",
      updateBteDevice: baseUrl + "api/admin/device/speechprocessor/update",

      //  fetchPatient: baseUrl + "api/device/fetchPatients",
      fetchPatient: baseUrl + "api/admin/patient/listAllById",
      // savePatientDetail: baseUrl + "api/device/savePatientDetails",
      savePatientDetail: baseUrl + "api/admin/patient/create",
      // fetchActiveTherapist: baseUrl + "api/therapist/fetchActiveTherapist",
      fetchActiveTherapist: baseUrl + "api/therapist/getActiveTherapists",
      // updatePatientDetail: baseUrl + "api/device/updatePatientDetail",
      updatePatientDetail: baseUrl + "api/admin/patient/update",
      // fetchImplantSerial: baseUrl + "api/device/fetchImplantSerial",
      fetchImplantSerial:
        baseUrl + "api/admin/device/getunassignedimplant/list",
      // fetchSpeechSerial: baseUrl + "api/device/fetchSpeechSerial",
      fetchSpeechSerial:
        baseUrl + "api/admin/device/getunassignedspeechprocessor/list",
      // deletePatientDetails: baseUrl + "api/device/deletepatient",
      deletePatientDetails: baseUrl + "api/admin/patient/unassignpatient",
      signuppatient: loginBaseUrl + "api/public/signuppatient",
      addDefaultCourse: baseUrl + "api/admin/postlingualexercise/update",
      addGoals: baseUrl + "api/contentpackage/contentDetails/addgoals",
    

    },
    courseContent: baseUrl + "courseContent",
    lessonOverView: baseUrl + "api/course/lessonOverview",
    // lessonOverView: baseUrl + "api/postlingual/exerciseOverview",
    getCourse: baseUrl + "api/coursecontent/getCourse",
    reports: baseUrl + "api/reports/assessmentReports",
    coursecategory: baseUrl + "api/postlingual/list/coursecategory",
  },
};
