<!-- <circle-progress id="circle-progress-complete" *ngIf="percentage == 100" [percent]="percentage" [radius]="25"
    [outerStrokeWidth]="5" [innerStrokeWidth]="5" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="'#00ff00'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300"
    [space]="-5" [maxPercent]="100" imageSrc="/assets/imgs/162_ic_check_green.svg" [showImage]="true"
    [showBackground]="true" [imageHeight]="25" [imageWidth]="25"
    style="width: 100%;display: flex;justify-content: center;">
</circle-progress>



<circle-progress id="circle-progress" *ngIf="percentage != 100" [percent]="percentage" [radius]="25"
    [outerStrokeWidth]="5" [innerStrokeWidth]="5" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="'#BED732'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300"
    [space]="-5" [maxPercent]="100" style="width: 100%;display: flex;justify-content: center;">
</circle-progress>


<div style="font-size:8px;font-weight: 500;color: #000000;">{{ percentage == 100 ? 'Completed': percentage == 0 ? 'Yet
    to start' : 'In-progress' }}
</div> -->

<circle-progress [id]="'circle-progress-complete'" *ngIf="percentage == 100" [percent]="percentage" [radius]="45"
    [outerStrokeWidth]="10" [innerStrokeWidth]="10" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="'#82DC73'" [innerStrokeColor]="'#C8D2DD'" [animation]="true" [animationDuration]="300"
    [space]="-10" [maxPercent]="100" imageSrc="/assets/imgs/162_ic_check_green.svg" [showImage]="true"
    [showBackground]="true" [imageHeight]="45" [imageWidth]="45"
    style="width: 100%;display: flex;justify-content: center;">
</circle-progress>

<!-- <circle-progress id="circle-progress" *ngIf="percentage == 0" [percent]="percentage" [radius]="25"
    [outerStrokeWidth]="5" [innerStrokeWidth]="5" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="'#e7e8ea'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300"
    [space]="-5" [maxPercent]="100" style="width: 100%;display: flex;justify-content: center;">
</circle-progress> -->
<circle-progress [id]="'circle-progress'" *ngIf="percentage >= 0 && percentage < 100" [percent]="percentage" [radius]="45"
    [outerStrokeWidth]="10" [innerStrokeWidth]="10" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="percentage == 0 ? '#e7e8ea' : '#82DC73'" [innerStrokeColor]="'#e7e8ea'" [animation]="true"
    [animationDuration]="300" [space]="-10" [maxPercent]="100" [titleFontSize]="40" [unitsFontSize]="23" [titleFontWeight]="600"
    style="width: 100%;display: flex;justify-content: center">
</circle-progress>


<!-- <circle-progress id="circle-progress" *ngIf="percentage != 100" [percent]="percentage" [radius]="25"
    [outerStrokeWidth]="5" [innerStrokeWidth]="5" [showtitle]="false" [showSubtitle]="false" [showInnerStroke]="true"
    [outerStrokeColor]="'#e7e8ea'" [innerStrokeColor]="percentage == 0 ? '#e7e8ea' : '#5E81F4'" [animation]="true"
    [animationDuration]="300" [space]="-5" [maxPercent]="100"
    style="width: 100%;display: flex;justify-content: center;">
</circle-progress> -->
<!-- <div  class="font">{{ percentage == 100 ? 'Completed':'Not Completed' }} -->

    <div>{{ percentage == 100 ? 'Completed': percentage == 0 ? 'Not Started' : 'In-progress' }}
</div>