import { Injectable } from "@angular/core";
// import { Cipher } from "crypto";
import * as CryptoJS from "crypto-js";
// import { decode } from "punycode";

@Injectable({
  providedIn: "root",
})
export class EncrDecrService {
  secretKey = "aesEncryptionKey";
  initVector = "encryptionIntVec";
  constructor() {}

  set(value): any {
    var key = CryptoJS.enc.Utf8.parse(this.secretKey);
    var iv = CryptoJS.enc.Utf8.parse(this.initVector);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // console.log("token is :" + encrypted);
    return encrypted.toString();
  }

  get(value) {
    var key = CryptoJS.enc.Utf8.parse(this.secretKey);
    var iv = CryptoJS.enc.Utf8.parse(this.initVector);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    // console.log("DecryptedText = ", decryptedText);
    return decryptedText;
    // const data = CryptoJS.enc.Utf8.stringify(decrypted);
    // console.log("decrypted", decrypted);
    // console.log("decryptedtostrint", decrypted.toString());
    // var decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    // var encryptedValue = Base64.getEncoder().encodeToString(decrypted);
    // return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptvideo(videoData:string ,key:string): Uint8Array {
    const encryptVideoData = CryptoJS.AES.encrypt(CryptoJS.lib.WordArray.create(videoData),key);
    return new Uint8Array(encryptVideoData.sigBytes);
  }
}
