import { TitleCasePipe } from "@angular/common";
import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-lesson-card",
  templateUrl: "./lesson-card.component.html",
  styleUrls: ["./lesson-card.component.scss"],
})
export class LessonCardComponent implements OnInit {
  @Input() lessonObject: any;
  @Output() lessonClick = new Subject();

  constructor(private titleCase: TitleCasePipe) {}

  ngOnInit(): void {}

  onClick(lessonObject) {
    this.lessonClick.next(lessonObject);
  }
}
