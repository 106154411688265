import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";
import { UiUtilService } from "../_services/ui-utils/ui-util.service";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  errorMessage: any;
   cnfrmDialog:any;
  constructor(private uiUtils: UiUtilService, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
     req = req.clone({
      withCredentials: true,
    });
    // if (req.url != "https://mymedtechapp.com:8081/api/login") {
    //   req = req.clone({
    //      withCredentials: true,
    //   });
    // } else {
    //   req = req.clone({
    //      withCredentials: false,
    //   });
    // }
    return next.handle(req);

    // return next.handle(req).pipe(
    //   catchError((ErrorResponse: HttpErrorResponse) => {
    //     if (ErrorResponse.error.message) {
    //       if(ErrorResponse.error.message){
    //              this.cnfrmDialog = this.uiUtils.showInfoDialog({
    //               message: ErrorResponse.error.message,
    //               title: "Error",
    //             });
    //           }
    //     } else if (ErrorResponse.status === 404) {
    //       this.cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: ErrorResponse.status+"API not found",
    //         title: "Error",
    //       });
    //     }
    //     else if (ErrorResponse.status === 500) {
    //       this.cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: ErrorResponse.status+"Internal server error",
    //         title: "Error",
    //       });
    //     }
    //     else if(ErrorResponse.status===0){
    //        this.cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: "Please contact administrator",
    //         title: "Error",
    //       });
    //     }
    //     console.log(ErrorResponse);
    //     // if(ErrorResponse){
 
    //     //   if(ErrorResponse.error.message){
    //     //     const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //       message: ErrorResponse.error.message,
    //     //       title: "Error",
    //     //     });
    //     //   }
    //     //   else{
    //     //     const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //       message: "Please contact administrator",
    //     //       title: "Error",
    //     //     });
    //     //   }
         
    //     // }
    //     let errorMsg = "";
    //     // if (error.error instanceof ErrorEvent) {
    //     //   // console.log('this is client side error');
    //     //   errorMsg = `Error: ${error.error.message}`;
    //     // } else {
    //     //   // console.log('this is server side error');
    //     //   errorMsg = `Error Code: ${error.status}, Message: ${error.error.message}`;
    //     //   const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //     message: error.error.message,
    //     //     title: "Error",
    //     //   });
    //     //   cnfrmDialog.result.then((result) => {
    //     //     if (result === true) {
    //     //     }
    //     //   });
    //     // }
    //     // console.log(errorMsg);
    //     return throwError(ErrorResponse.error.message);
    //   })
    // );
  
    // return next.handle(req).pipe(
    //   catchError((ErrorResponse: HttpErrorResponse) => {
    //     if (ErrorResponse.error.message) {
    //       const cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: ErrorResponse.error.message,
    //         title: "Error",
    //       });
    //     }
    //     else {
    //       const cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: "Please contact administrator",
    //         title: "Error",
    //       });
    //     }
    //     console.log(ErrorResponse);
    //     // if(ErrorResponse){
 
    //     //   if(ErrorResponse.error.message){
    //     //     const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //       message: ErrorResponse.error.message,
    //     //       title: "Error",
    //     //     });
    //     //   }
    //     //   else{
    //     //     const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //       message: "Please contact administrator",
    //     //       title: "Error",
    //     //     });
    //     //   }
         
    //     // }
    //     let errorMsg = "";
    //     // if (error.error instanceof ErrorEvent) {
    //     //   // console.log('this is client side error');
    //     //   errorMsg = `Error: ${error.error.message}`;
    //     // } else {
    //     //   // console.log('this is server side error');
    //     //   errorMsg = `Error Code: ${error.status}, Message: ${error.error.message}`;
    //     //   const cnfrmDialog = this.uiUtils.showInfoDialog({
    //     //     message: error.error.message,
    //     //     title: "Error",
    //     //   });
    //     //   cnfrmDialog.result.then((result) => {
    //     //     if (result === true) {
    //     //     }
    //     //   });
    //     // }
    //     // console.log(errorMsg);
    //     return throwError(ErrorResponse.error.message);
    //   })
    // );
    // return next.handle(req).pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     // if (error.status === 500) {
    //     //   this.handle500Error(error);
    //     // } else if (error.status === 404) {
    //     //   this.handle404Error(error);
    //     // } else if (error.status === 0) {
    //     //   this.handle0Error(error);
    //     // } else {
    //     //   this.handleOtherError(error);
    //     // }
    //     let errorMsg = "";
    //     if (error.error instanceof ErrorEvent) {
    //       // console.log('this is client side error');
    //       errorMsg = `Error: ${error.error.message}`;
    //     } else {
    //       // console.log('this is server side error');
    //       errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;
    //       const cnfrmDialog = this.uiUtils.showInfoDialog({
    //         message: error.message,
    //         title: "Error",
    //       });
    //       cnfrmDialog.result.then((result) => {
    //         if (result === true) {
    //         }
    //       });
    //     }
    //     // console.log(errorMsg);
    //     return throwError(this.errorMessage);
    //   })
    // );
  }
  // private handle500Error = (error: HttpErrorResponse) => {
  //   this.createErrorMessage(error);
  //   console.log("500 error", error);
  //   this.router.navigate(["/500"]);
  // };
  // private handle0Error = (error: HttpErrorResponse) => {
  //   // error.message = "Please check your internet Connection";
  //   this.createErrorMessage(error);
  //   console.log("0 error", error);
  //   this.router.navigate(["/500"]);
  // };

  // private handle404Error = (error: HttpErrorResponse) => {
  //   this.createErrorMessage(error);
  //   this.router.navigate(["/404"]);
  //   console.log("404 error", error);
  // };

  // private handleOtherError = (error: HttpErrorResponse) => {
  //   this.createErrorMessage(error);
  //   console.log("other errors", error);
  //   //TODO: this will be fixed later;
  // };

  // private createErrorMessage = (error: HttpErrorResponse) => {
  //   this.errorMessage = error.error ? error.error : error.message;
  // };
}
