import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { UiUtilService } from "src/app/_services/ui-utils/ui-util.service";
import { UserAuthenticationService } from "src/app/_services/user-authentication.service";
import constant from "src/constants/MTConstants";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  closeResult = "";
  selectedOption: string = "";
  optionList: any[] = ["Profile", "Change Password", "Logout"];
  therapistId: string;
  therapistProfile: any;
  therapistname: any;
  careId: any;
  therapistPhone: any;
  flowType: any;
  adminDetails: any;

  constructor(
    private modalService: NgbModal,
    private uiUtils: UiUtilService,
    private api: RestApiService,
    private router: Router,
    private authenticationService: UserAuthenticationService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.flowType = this.localStorage.get("flowType");
    this.careId = this.localStorage.get(constant.loginScreen.careId);
    if (this.flowType === "Admin") {
      // this.getAdminProfile();
    } else {
     this.therapistId = this.localStorage.get("therapistId");
     this.getProfileData();
    }
  }
    
   

 
  

  getAdminProfile() {
    let requestBody = "/"+this.careId
    this.api.getAdminDetailsById(requestBody).subscribe((res) => {
      this.adminDetails = res;
    });
  }
  
  getProfileData(): void {
    let requestBody = { id: this.therapistId };
    this.api.getTherapistDetailsById(requestBody).subscribe((res) => {
      this.therapistProfile = res.data;
      this.therapistname = res.data.therapistFirstName;
      // this.careId = res.data.therapistQualification;
      this.therapistPhone = res.data.therapistPhone;
    });
  }

  open(content) {
    this.modalService
      .open(content, {
        windowClass: "profile-modal",
        backdropClass: "light-blue-backdrop",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  selectOption(e) {
    this.selectedOption = e.target.innerHTML;
    if (this.selectedOption === "Change Password") {
      this.modalService.dismissAll();
      const cnfrmDialog = this.uiUtils.showChangePasswordDialog({
        message: "Change Password",
      });
    } else if (this.selectedOption === "Logout") {
      this.authenticationService.onLogout();
    }
  }
}
