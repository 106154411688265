import { Injectable } from '@angular/core';
import { RestApiService } from './rest-api/rest-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { PatientService } from './patient/patient.service';
import constant from 'src/constants/MTConstants';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from './local-storage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
 

  constructor(private api: RestApiService, 
    private router: Router,
    private modelService: NgbModal,
    private localStorage: LocalStorageService,
    private patientService: PatientService) {
  }

  onLogin(userDetails): Observable<any> {
 
    return this.api.login(userDetails);
  }

  
  onHealthCheckAPI(userDetails): Observable<any> {
 
    return this.api.login(userDetails);
  }

  // getTherapistDetails(userDetails): Observable<any> {
 
  //   return this.api.getTherapistDetailsById(userDetails);
  // }

  onLogout(){
    let userSessionKey = this.localStorage.get(constant.loginScreen.userToken);
        let logOutParameter = {
          token: userSessionKey,
        };
        this.api.logout(logOutParameter).subscribe((res) => {
          if (res) {
          sessionStorage.clear();
          this.modelService.dismissAll();
          this.localStorage.clear();
          this.localStorage.remove(constant.loginScreen.userToken);
          this.localStorage.remove('ActiveTab');
          this.patientService.clearData();
          sessionStorage.clear();
          // localStorage.removeItem(constant.loginScreen.userToken);
          this.router.navigate(["/"]);
          alert(res.message);
          }
        });
  }

  OnforgotPassword(userDetails): Observable<any> {
    return this.api.forgotPassword(userDetails);
  }
  OnchangePassword(userDetails): Observable<any> {
    return this.api.changePassword(userDetails);
  }

  
}
