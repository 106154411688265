<div (focus)="onInputFocus()" style="position: relative;">
        <div class="placeHolder" *ngIf="selectedVal">{{SelectHere}}</div>
        <label class="dropdownLabel">
                <select  [id]="dropdownId" class="dropdownSelect"
                        [ngClass]="outlined ? 'form-rounded' : 'form-underline'" #input [(ngModel)]="selected"
                        name="customDropDown" [disabled]="disabled" (ngModelChange)="onOptionsSelected($event)"
                        (focus)="onInputFocus()" (blur)="onInputBlur()">
                        <option *ngFor="let list of inputLists;let i=index;trackBy: trackByFn" [ngValue]="list">{{list}}
                        </option>
                </select>
        </label>
</div>


<!-- <div class="container">
        <div class="select-btn" (click)="showCheckbox($event)">
                <span class="btn-text">Select Here</span> 
                <span class="arrow-dwn">
                        <i class="fa-chevron-down" > </i>
                </span>    
        </div>

        <ul class="list-items"  *ngIf= "expand">
                <li class="item" >
                        <span class="checkbox">
                                <i  class="fa-solid fa-check check-icon"></i>
                               
                        </span>
                        <span class="item-text"> sound</span>
                </li>
                <li class="item">
                        <span class="checkbox" (click)="checkvalue($event)">
                                <i  class="fa-solid fa-check check-icon"></i>
                        </span>
                        <span class="item-text"> voice</span>
                </li>

                <li class="item">
                        <span class="checkbox">
                                <i  class="fa-solid fa-check check-icon"></i>
                        </span>
                        <span class="item-text"> environment</span>
                </li>
        </ul>

</div> -->