import { Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PatientCommonService {
  constructor() {}

  public searchSubject = new BehaviorSubject<number>(1); // Value in brackets is default value of subject

  searchSubscriber$ = this.searchSubject.asObservable();

  updateLessonLevel(searchString: number) {
    // Whenever this method is called, it's  handler function will be called.
    this.searchSubject.next(searchString);
  }
  public leftSubject = new BehaviorSubject<boolean>(false); // Value in brackets is default value of subject

  leftSubscriber$ = this.searchSubject.asObservable();

  updateLeftPanel(searchString: boolean) {
    // Whenever this method is called, it's handler function will be called.
    this.leftSubject.next(searchString);
  }
}
