import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  EmailValidator,
} from "@angular/forms";
import { PatientService } from "src/app/_services/patient/patient.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { debounceTime } from "rxjs/operators";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { DashboardService } from "../../_services/Dashboard/dashboard.service";
import { Subject } from "rxjs";
import { TherapistDetailsComponent } from "src/app/admin/therapist-details/therapist-details.component";

@Component({
  selector: "app-add-patient",
  templateUrl: "./add-patient.component.html",
  styleUrls: ["./add-patient.component.scss"],
})
export class AddPatientComponent implements OnInit {
  @ViewChild("fileInput") el: ElementRef;

  isEditAction = false;
  isFormReady = false;
  editFile = true;
  removeUpload = false;
  submitted: boolean;

  patientDetails: any;
  patientName = "";
  imageUrl: any = "";
  dropdownSelected: string;
  private _success = new Subject<string>();

  successMessage = "";

  public genderList: any[] = [
    { label: "Male", checked: false, disabled: false },
    { label: "Female", checked: false, disabled: false },
  ];
  dropdownLists: any[] = ["Yes", "No"];
  firstNameTouched: boolean = false;

  addPatientForm: FormGroup = new FormGroup({});
  registrationForm = this.fb.group({
    file: [null],
  });
  therapistId: any;
  selectedDevice: any;
  flowType: string = "preLingual";

  constructor(
    private patientService: PatientService,
    private dashboardService: DashboardService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private localStorage: LocalStorageService,
    private api: RestApiService
  ) {
    this.getQueryParams();
  }

  ngOnInit(): void {
    this.dropdownSelected = this.dropdownLists[0];
    this.therapistId = this.localStorage.get("therapistId");
    this.flowType = this.localStorage.get("flowType");
    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(2000)).subscribe(() => {
      this.successMessage = "";
      // this.router.navigate(["dashboard/home/stats"]);

      if (this.flowType === "preLingual") {
        this.router.navigate(["therapist/preLingual/dashboard"]);
      } else if (this.flowType === "postLingual") {
        this.router.navigate(["therapist/postLingual/dashboard"]);
      }
    });
  }
  // Function to get query Params (either create of edit mode)
  getQueryParams(): void {
    this.activeRoute.queryParams.subscribe((res) => {
      const action = res.action;
      const id = res.id;
      if (this.isEditActionRequested(action, id)) {
        this.isEditAction = true;
        this.prePopulatePatientData();
      } else {
        this.isEditAction = false;
        this.selectedDevice = this.localStorage.get("selectedDevice");
        this.initializePatientsForm({
          patientFirstName: "",
          patientLastName: "",
          patientPhone: "",
          patientEmail: "",
          avd: "",
          notes: "",
          patientDOB: "",
          patientGender: "",
          patientImplantDate: "",
          bteSerialNumber: this.selectedDevice.bteSerialNumber,
          implantSerialNumber: this.selectedDevice.implantSerialNumber,
          switchOnDate: this.formatDate(this.selectedDevice.switchOnDate),
          // isVaccinated: "Yes",
          id: new Date().getTime(),
        });
      }
    });
  }
  //Function to pre-populate date.
  prePopulatePatientData(): void {
    if (this.patientService.currentSelectedPatient == undefined) {
      this.patientDetails = this.localStorage.get("currentSelectedPatient");
    } else {
      this.patientDetails = this.patientService.currentSelectedPatient;
    }
    if (this.patientDetails.deviceDetails) {
      this.patientDetails.bteSerialNumber =
        this.patientDetails.deviceDetails.bteSerialNumber;
      this.patientDetails.implantSerialNumber =
        this.patientDetails.deviceDetails.implantSerialNumber;
    } else {
      this.patientDetails.bteSerialNumber = null;
      this.patientDetails.implantSerialNumber = null;
    }

    this.patientName = this.patientDetails.patientFirstName;
    if (this.patientDetails.profilepic) {
      this.imageUrl = this.patientDetails.profilepic;
    }

    this.patientDetails.patientDOB = this.patientDetails.patientDOB
      ? this.formatDate(this.patientDetails.patientDOB)
      : null;

    this.patientDetails.patientImplantDate = this.patientDetails
      .patientImplantDate
      ? this.formatDate(this.patientDetails.patientImplantDate)
      : null;

    this.patientDetails.switchOnDate = this.patientDetails.switchOnDate
      ? this.formatDate(this.patientDetails.switchOnDate)
      : null;

    if (this.patientDetails.isVaccinated == "Yes")
      this.patientDetails.avd = "Meningitis vaccination done";
    else this.patientDetails.avd = "Meningitis vaccination not done";

    this.initializePatientsForm(this.patientDetails);
  }

  //Setting the date format
  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    // return [year, month, day].join("-");
    return { year: year, month: Number(month), day: Number(day) };
  }
  //Initializing the patient reactive form.
  initializePatientsForm(formData: any): void {
    //console.log(formData);
    this.addPatientForm = this.fb.group({
      patientFirstName: [formData.patientFirstName, Validators.required],
      patientLastName: [formData.patientLastName, Validators.required],
      patientPhone: [
        formData.patientPhone,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern("[0-9]*"),
          // Validators.pattern("[- +()0-9]+"),
        ],
      ],
      patientEmail: [
        formData.patientEmail,
        [Validators.required, Validators.email],
      ],
      avd: [formData.avd, Validators.required],
      bteSerialNumber: [formData.bteSerialNumber, Validators.required],
      implantSerialNumber: [formData.implantSerialNumber, Validators.required],
      switchOnDate: [formData.switchOnDate, Validators.required],
      notes: [formData.notes, Validators.required],
      patientDOB: [formData.patientDOB, Validators.required],
      patientImplantDate: [formData.patientImplantDate, Validators.required],
      patientGender: [formData.patientGender, Validators.required],
      // isVaccinated: [formData.isVaccinated, Validators.required],
      imageUrl: [formData.imageUrl],
      // therapistId: [formData.id]
      therapistId: this.therapistId,
    });
    //console.log(this.addPatientForm);
    this.setFormReady(true);
  }

  setFormReady(status: boolean): void {
    this.isFormReady = status;
  }

  isEditActionRequested(action: string, id: string): boolean {
    return action && action === "edit";
  }

  onTileDropdown(event: any) {}

  onAddButtonClick(): void {
    if (this.isEditAction) {
      this.updatePatient();
    } else {
      this.createPatient();
    }
  }
  //On Click of Update
  updatePatient(): void {
    let patientDetailsLocal = this.addPatientForm.value;

    patientDetailsLocal["id"] = this.patientDetails.id;
    delete patientDetailsLocal["avd"];
    delete patientDetailsLocal["imageUrl"];

    patientDetailsLocal.patientDOB = this.formatDateRqst(
      patientDetailsLocal.patientDOB
    );
    patientDetailsLocal.patientImplantDate = this.formatDateRqst(
      patientDetailsLocal.patientImplantDate
    );
    patientDetailsLocal.switchOnDate = this.formatDateRqst(
      patientDetailsLocal.switchOnDate
    );
    // patientDetailsLocal.patientGender = patientDetailsLocal.patientGender.toUpperCase();
    if (patientDetailsLocal.isVaccinated == "No")
      patientDetailsLocal.isVaccinated = false;
    else patientDetailsLocal.isVaccinated = true;
    patientDetailsLocal.therapistId = this.therapistId;
    if (this.flowType === "preLingual") {
      // this.router.navigate(["dashboard"]);
      this.api.updatePatientDetails(patientDetailsLocal).subscribe((res) => {
        if (res) {
          //Toast message we need
          //  res.Message = "Patient details Updated successfully";
          this._success.next(res.responseMessage);
        }
      });
    } else if (this.flowType === "postLingual") {
      patientDetailsLocal.patientCategory = "postlingual";
      this.api
        .savePostLingualPatientDetails(patientDetailsLocal)
        .subscribe((res) => {
          if (res) {
            //Toast message we need
            //  res.Message = "Patient details Updated successfully";
            this._success.next(res.responseMessage);
          }
        });
    }
  }
  //On Click of Update end

  //On Click of Save
  createPatient(): void {
    console.log(this.addPatientForm.controls.patientFirstName.touched);
    let patientDetailsLocal = this.addPatientForm.value;
    if (this.flowType === "preLingual") {
      // this.router.navigate(["dashboard"]);
    } else if (this.flowType === "postLingual") {
      patientDetailsLocal.patientCategory = "postlingual";
    }

    // patientDetailsLocal['profilepic'] = this.imageUrl;
    delete patientDetailsLocal["avd"];
    delete patientDetailsLocal["imageUrl"];
    // patientDetailsLocal.patientGender = patientDetailsLocal.patientGender.toUpperCase();
    patientDetailsLocal.isVaccinated =
      patientDetailsLocal.isVaccinated === "Yes" ? "true" : "false";

    patientDetailsLocal.patientDOB = this.formatDateRqst(
      patientDetailsLocal.patientDOB
    );
    patientDetailsLocal.patientImplantDate = this.formatDateRqst(
      patientDetailsLocal.patientImplantDate
    );
    patientDetailsLocal.switchOnDate = this.formatDateRqst(
      patientDetailsLocal.switchOnDate
    );
    patientDetailsLocal.therapistId = this.therapistId;
    if (this.flowType === "preLingual") {
      // this.router.navigate(["dashboard"]);
      this.patientService
        .addNewPatient(patientDetailsLocal)
        .subscribe((res) => {
          if (res) {
            //Toast message we need
            //  res.Message = "Patient record created successfully";
            this._success.next(res.responseMessage);
            // this.router.navigate(['dashboard/home/stats']);
          } else {
          }
        });
    } else if (this.flowType === "postLingual") {
      patientDetailsLocal.patientCategory = "postlingual";
      this.api
        .savePostLingualPatientDetails(patientDetailsLocal)
        .subscribe((res) => {
          if (res) {
            //Toast message we need
            //  res.Message = "Patient record created successfully";
            this._success.next(res.responseMessage);
            // this.router.navigate(['dashboard/home/stats']);
          } else {
          }
        });
    }
  }

  formatDateRqst(e) {
    const stringMonth = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date = [e.day, stringMonth[e.month - 1], e.year].join("-");
    return date;
  }
  //Image Upload
  uploadFile(event: any) {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.registrationForm.patchValue({
          file: reader.result,
        });
        this.editFile = false;
        this.removeUpload = true;
      };
      this.cd.markForCheck();
    }
  }

  // Function to remove uploaded file
  removeUploadedFile() {
    const newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl =
      "https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg";
    this.editFile = true;
    this.removeUpload = false;
    this.registrationForm.patchValue({
      file: [null],
    });
  }
  // On Submit  of image
  onSubmit() {
    this.submitted = true;
    if (!this.registrationForm.valid) {
      alert("Please fill all the required fields to create a super hero!");
      return false;
    } else {
      //console.log(this.registrationForm.value);
    }
  }

  onPatientSave() {
    //console.log(this.addPatientForm);
  }
  // On click of reset
  onResetButtonClick() {
    this.addPatientForm.reset();
    this.dashboardService.genderSubject.next(
      this.addPatientForm["controls"].patientGender.value
    );
  }

  onFirstname($event) {
    this.firstNameTouched = true;
  }

  onClose() {
    if (this.flowType === "preLingual") {
      this.router.navigate(["therapist/preLingual/dashboard"]);
    } else if (this.flowType === "postLingual") {
      this.router.navigate(["therapist/postLingual/dashboard"]);
    }
  }

  onSearchClick() {
    if (this.flowType === "preLingual") {
      this.router.navigate(["therapist/preLingual/dashboard/addImplantDevice"]);
    } else if (this.flowType === "postLingual") {
      this.router.navigate([
        "therapist/postLingual/dashboard/addImplantDevice",
      ]);
    }
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
