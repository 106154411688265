import { Component, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-dropdown-with-search",
  templateUrl: "./dropdown-with-search.component.html",
  styleUrls: ["./dropdown-with-search.component.scss"],
})
export class DropdownWithSearchComponent implements OnInit {
  selectedRollUpGroup: string = "";
  showSearch: boolean = false;
  @Input() dataList: any[];
  @Output() dropdownSelect = new Subject();
  dataListCopy: any[];
  @Input() selectedData: any;
  showSearchFlag: boolean = true;
  dropDownIdGroup = [
    "op",
    "search-icon",
    "custom-dropdown-list-ul",
    "custom-dropdown-filter",
    "id-custom-dropdown-wrapper",
    "down",
    "up",
    "id-custom-dropdown-selected",
    "id-custom-dropdown-list-panel",
  ];

  constructor() {}

  ngOnInit(): void {
    this.selectedRollUpGroup = this.selectedData.bteSerialNumber
      ? this.selectedData.bteSerialNumber
      : "Select BTE Number";
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  openDropDown() {
    this.showSearch = !this.showSearch;
    this.dataListCopy = [...this.dataList];
  }

  filterList(key, value) {
    this.dataList = this.dataListCopy.filter((x) => {
      if (x.bteSerialNumber) {
        return x.bteSerialNumber.toLowerCase().includes(value.toLowerCase());
      }
    });
    this.showSearch = true;
  }
  selectItem(rollupGroup, rollupGroupIndex) {
    this.showSearch = !this.showSearch;
    this.selectedData = rollupGroup;
    this.selectedRollUpGroup = rollupGroup.bteSerialNumber;
    this.dropdownSelect.next(this.selectedData);
  }

  onButtonClick() {
    this.dataListCopy = [...this.dataList];
    this.selectedRollUpGroup = "";
    this.showSearch = false;
    this.showSearchFlag = !this.showSearchFlag;
    if (this.showSearchFlag) {
      this.selectedRollUpGroup = "Select BTE Number";
    }
  }
  // confirmDelete(index) {
  //   this.dataList[index].checked = !this.dataList[index].checked;
  //   this.selectedData = this.dataList.filter((data) => data.checked);
  // }
}
