import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-default-card',
  templateUrl: './admin-default-card.component.html',
  styleUrls: ['./admin-default-card.component.scss']
})
export class AdminDefaultCardComponent implements OnInit {
  currentPlayingVideo: HTMLVideoElement;
  @Input() vedioSrc: any[];
  @Input() exerciceTitle: any;
  @Output() public linkedClicked = new EventEmitter<String>();
  @Input() selected:boolean;
  @Input() selectedall:boolean;
  checkboxElement: any;


  constructor() { }

  ngOnInit(): void {
  }

  //to  make checked true after clicking selection button
  onLinkClicked(list, event:any) {
    this.checkboxElement=event.srcElement.parentElement.parentElement.parentElement.children[0];
    if(list.isDefault==false){
      this.checkboxElement.checked=true;
    }
  }

  //toggling play and pause for video
  toggleVideo(videoObject){
    if(videoObject.paused){
      if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = videoObject;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last
    // one and play the new one
        if (videoObject !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = videoObject;
            this.currentPlayingVideo.play();
        }
        else if(videoObject === this.currentPlayingVideo){
          videoObject.play()
        }
    }
    }
    else{
      videoObject.pause()
    }
   
  }
  
  //enlarging video
  enlargeVideo(videoObject){
    if (videoObject.requestFullscreen) {
      videoObject.requestFullscreen();
  } 
  }
  
  //toggling full screen
  toggleFullScreen(videoObject) {
    if (!document.exitFullscreen) {
      videoObject.requestFullscreen();
        }
    else {
      videoObject.exitFullscreen();
        }
    
      }
}
