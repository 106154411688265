import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartType } from "chart.js";
import { Color } from "ng2-charts";

@Component({
  selector: "app-modal-statistics",
  templateUrl: "./modal-statistics.component.html",
  styleUrls: ["./modal-statistics.component.scss"],
})
export class ModalStatisticsComponent implements OnInit {
  @Input() title: any;
  @Input() data: any;

  public catList: any[] = [
    { label: "In Numbers", checked: false, disabled: false },
    { label: "In Percent", checked: false, disabled: false },
  ];
  selectedCat: string = "In Numbers";
  public barChartOptions: any;
  public barChartColorsByNumber: Color[] = [
 
    { backgroundColor: "#82DC73" },
    { backgroundColor: "#FF789B" },
  ];
  public barChartColorsByPercentage: Color[] = [
 
    { backgroundColor: "#82DC73" },
    { backgroundColor: "#FF789B" },
  ];
  public barChartColors: Color[];
  public barChartOptionsPercentage: any = {
    // barThickness: 16,
 
    responsive: true,
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        boxWidth: 8,
        fontSize:9,
        fontColor:"#19191E",
        fontFamily:"HCLTechRoobert",
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize:10,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0",
          },
          // barPercentage: 0.65,
          barPercentage: 0.8,
          categoryPercentage: 0.4,
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: true,
          },
          ticks: {
            beginAtZero: true,
            stepSize :20,
            fontSize:10,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0",
            //
           
            userCallback: function (label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label + " %";
              }
            },
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          var percentage = "";
 
          return percentage;
          // var percentage = value + "%";
          // return percentage;
        },
        //If we disbale below two line then number percentage will display in graph.
        anchor: "centre",
        align: "centre",
        color: "#333333",
      },
    },
  };
 
  public barChartOptionsNumber: any = {
    // barThickness: 16,
 
    responsive: true,
    // maintainAspectRatio:false,
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        boxWidth: 8,
        fontSize:9,
        fontColor:"#19191E",
        fontFamily:"HCLTechRoobert",
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize:10,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0",
          },
          // barPercentage: 0.65,
          barPercentage: 0.8,
          categoryPercentage: 0.4,
          // categoryPercentage:1.0,
          // barValueSpacing:1.0
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: true,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 5,
            fontSize:10,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0",
 
            userCallback: function (label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          // var percentage = value + "%";
          // return percentage;
          value = "";
 
          return value;
        },
        //If we disbale below two line then number percentage will display in graph.
        anchor: "centre",
        align: "centre",
        color: "#333333",
      },
    },
  };

  public barChartType: ChartType = "bar";
  public barChartLegend = true;

  barChartLabels: any;
  barChartData: { data: any; label: string }[];

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.setStatisticsDataByCount();
    // this.modalService.open(content,{
    //   windowClass: "statistics-modal",
    // })
    // this.modalService.open('', { windowClass: 'statistics-modal' });
  }

  setStatisticsDataByPercentage() {
    this.barChartColors = this.barChartColorsByPercentage;
    this.barChartOptions = this.barChartOptionsPercentage;
    let percentageCompletionArr = [];
    let percentageinCompletionArr = [];
    let barChartLabelsArr = [];
    this.data.forEach((selectedVal) => {
      let percentageCompletion = Math.round(
        (selectedVal.exerciseResponse.correct /
          (selectedVal.exerciseResponse.correct +
            selectedVal.exerciseResponse.incorrect)) *
          100
      );
      let percentageinCompletion = Math.round(
        (selectedVal.exerciseResponse.incorrect /
          (selectedVal.exerciseResponse.correct +
            selectedVal.exerciseResponse.incorrect)) *
          100
      );
      percentageCompletionArr.push(percentageCompletion);
      percentageinCompletionArr.push(percentageinCompletion);
      selectedVal.combinationName
        ? barChartLabelsArr.push(selectedVal.combinationName)
        : barChartLabelsArr.push(selectedVal.exerciseName);
    });
    this.barChartData = [
      { data: percentageCompletionArr, label: "Correct " },
      { data: percentageinCompletionArr, label: "In Correct" },
    ];
    this.barChartLabels = barChartLabelsArr;
  }
  setStatisticsDataByCount() {
    this.barChartColors = this.barChartColorsByNumber;
    this.barChartOptions = this.barChartOptionsNumber;
    let percentageCompletionArr = [];
    let percentageinCompletionArr = [];
    let numberOfAttemptsArr = [];
    let barChartLabelsArr = [];
    this.data.forEach((selectedVal) => {
      percentageCompletionArr.push(selectedVal.exerciseResponse.correct);
      percentageinCompletionArr.push(selectedVal.exerciseResponse.incorrect);
      numberOfAttemptsArr.push(
        selectedVal.exerciseResponse.correct +
          selectedVal.exerciseResponse.incorrect
      );
      selectedVal.combinationName
        ? barChartLabelsArr.push(selectedVal.combinationName)
        : barChartLabelsArr.push(selectedVal.exerciseName);
    });

    // percentageCompletionArr = this.data.map(selectedVal=>selectedVal.exerciseResponse.correct);
    // percentageinCompletionArr = this.data.map(selectedVal=>selectedVal.exerciseResponse.incorrect);
    // numberOfAttemptsArr = this.data.map(selectedVal=>selectedVal.exerciseResponse.correct + selectedVal.exerciseResponse.incorrect);

    this.barChartData = [
      // { data: numberOfAttemptsArr, label: "Number of Attempts" },
      { data: percentageCompletionArr, label: "Correct" },
      { data: percentageinCompletionArr, label: "In Correct " },
    ];
    console.log(this.barChartData);
    this.barChartLabels = barChartLabelsArr;
  }
  onClose() {
    this.activeModal.close();
  }

  selectCat(event) {
    this.selectedCat = event;
    if (event == "In Numbers") {
      this.setStatisticsDataByCount();
    } else if (event == "In Percent") {
      this.setStatisticsDataByPercentage();
    }
  }
}
