<div class="outerDiv" [ngClass]="flowType === 'preLingual' ? 'pd-0' : null">
    <div class="header">
        <!-- <h5 class="title m-0 font-regular">Select BTE Device</h5> -->
        <div class="icon-mt-close-dark icon" ngbTooltip="Close" tooltipClass="my-custom-class" placement="bottom"
            (click)="onClose()"></div>
    </div>
    <div class="containerDiv">
        <div class="flex">
            <div class="w-1">
                <app-dropdown-with-search [selectedData]="selectedDevice" [dataList]="deviceList"
                    (dropdownSelect)="onDropdownSelect($event)"></app-dropdown-with-search>
            </div>
            <!-- <app-button id="saveBtn" style="margin-left:15px;" [disabled]="!selectedDevice.bteSerialNumber"
                [buttonText]="'Add Device to Patient'" (btnClick)="onAdd()"></app-button> -->
        </div>
    </div>
</div>