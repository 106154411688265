import { Component, EventEmitter, Input, OnInit, Output,ContentChild, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-all-course-card',
  templateUrl: './all-course-card.component.html',
  styleUrls: ['./all-course-card.component.scss']
})
export class AllCourseCardComponent implements OnInit {
  currentPlayingVideo: HTMLVideoElement;
  currentPlayingAudio: HTMLAudioElement;
  @Input() vedioSrc: any;
  @Input() exerciceTitle: any;

  @Output() public linkedClicked = new EventEmitter<String>();
// selected: boolean = true;

@Input() selected:boolean;
@Input() selectedall:boolean

  constructor() { }

  ngOnInit(): void {
  }
  
  parallelplay(src){
    if(src.localName=="audio"){
      if (this.currentPlayingVideo != undefined) {
        this.currentPlayingVideo.pause();
        this.ToggleAudio(src);
      }else{
        this.ToggleAudio(src);
      }  
    }else if(src.localName=="video"){
      if (this.currentPlayingAudio != undefined) {
        this.currentPlayingAudio.pause();
        this.ToggleVideo(src);
      }else{
        this.ToggleVideo(src);
      } 
    }
  }

  ToggleAudio(Audioo){
    if(Audioo.paused){
      if (this.currentPlayingAudio === undefined) {
        this.currentPlayingAudio = Audioo;
        this.currentPlayingAudio.play();
    } else {
    // if the user plays a new Audio, pause the last
    // one and play the new one
        if (Audioo !== this.currentPlayingAudio) {
            this.currentPlayingAudio.pause();
            this.currentPlayingAudio = Audioo;
            this.currentPlayingAudio.play();
        }
        else if(Audioo === this.currentPlayingAudio){
          Audioo.play()
        }
    }
    }
    else{
      Audioo.pause()
    }
  }

ToggleVideo(videooo){
  // videooo.preventDefault();
  if(videooo.paused){
    if (this.currentPlayingVideo === undefined) {
      this.currentPlayingVideo = videooo;
      this.currentPlayingVideo.play();
  } else {
  // if the user plays a new video, pause the last
  // one and play the new one
      if (videooo !== this.currentPlayingVideo) {
          this.currentPlayingVideo.pause();
          this.currentPlayingVideo = videooo;
          this.currentPlayingVideo.play();
      }
      else if(videooo === this.currentPlayingVideo){
        videooo.play()
      }
  }
  }
  else{
    videooo.pause()
  }
 
}

enlargeVideo(videooo){
  // if (videooo.requestFullscreen) {
  //   videooo.requestFullscreen();
  // } 
  videooo.requestFullscreen ? videooo.requestFullscreen():'';
}

}
