import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() transbg = false;
  copyrightText: string =
    "© " +
    new Date().getFullYear() +
    " - HCL Technologies - All rights reserved";

  constructor() {}

  ngOnInit(): void {}
}
