import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";

@Component({
  selector: "app-select-implant-device",
  templateUrl: "./select-implant-device.component.html",
  styleUrls: ["./select-implant-device.component.scss"],
})
export class SelectImplantDeviceComponent implements OnInit {
  // deviceList: any = [
  //   { bteSerialNum: "BTE0000000000036", implantSerialNum: "IMP0000000000036" },
  //   { bteSerialNum: "BTE0000000000035", implantSerialNum: "IMP0000000000035" },
  //   { bteSerialNum: "BTE0000000000034", implantSerialNum: "IMP0000000000034" },
  //   { bteSerialNum: "BTE0000000000033", implantSerialNum: "IMP0000000000033" },
  // ];
  selectedDevice: any = {};
  flowType: string = "preLingual";
  deviceList: any;
  // deviceList: any = [];

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private api: RestApiService
  ) {}

  ngOnInit(): void {
    this.flowType = this.localStorage.get("flowType");
    this.fetchDeviceList();
  }
  fetchDeviceList(): void {
    this.api.fetchDeviceList().subscribe((res) => {
      // res = [
      //   {
      //     id: 73,
      //     bteSerialNumber: "BTE0000000000909",
      //     implantSerialNumber: "IMP0000000000909",
      //   },
      //   {
      //     id: 74,
      //     bteSerialNumber: "BTE0000000000999",
      //     implantSerialNumber: "IMP0000000000999",
      //   },
      //   {
      //     id: 75,
      //     bteSerialNumber: "BTE0000000000998",
      //     implantSerialNumber: "IMP0000000000998",
      //   },
      //   {
      //     id: 76,
      //     bteSerialNumber: "BTE0000000000997",
      //     implantSerialNumber: "IMP0000000000997",
      //   },
      //   {
      //     id: 79,
      //     bteSerialNumber: "BTE0000000199995",
      //     implantSerialNumber: "IMP0000000199995",
      //   },
      //   {
      //     id: 80,
      //     bteSerialNumber: "BTE0000000199996",
      //     implantSerialNumber: "IMP0000000199996",
      //   },
      //   {
      //     id: 81,
      //     bteSerialNumber: "BTE0000000199997",
      //     implantSerialNumber: "IMP0000000199997",
      //   },
      //   {
      //     id: 82,
      //     bteSerialNumber: "BTE0000000199998",
      //     implantSerialNumber: "IMP0000000199998",
      //   },
      //   {
      //     id: 83,
      //     bteSerialNumber: "BTE0000000199999",
      //     implantSerialNumber: "IMP0000000199999",
      //   },
      // ];
      this.deviceList = res;
    });
  }
  onAdd() {
    this.localStorage.set("selectedDevice", this.selectedDevice);
    if (this.flowType === "preLingual") {
      this.router.navigate(["therapist/preLingual/dashboard/patient"], {
        queryParams: { action: "add", type: "preLingual" },
        queryParamsHandling: null,
      });
    } else if (this.flowType === "postLingual") {
      this.router.navigate(["therapist/postLingual/dashboard/patient"], {
        queryParams: { action: "add", type: "postLingual" },
        queryParamsHandling: null,
      });
    }
  }
  onDropdownSelect(event) {
    this.selectedDevice = event;
    this.onAdd();
  }

  onClose() {
    if (this.flowType === "preLingual") {
      this.router.navigate(["therapist/preLingual/dashboard"]);
    } else if (this.flowType === "postLingual") {
      this.router.navigate(["therapist/postLingual/dashboard"]);
    }
  }
}
