<ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = ''">{{ successMessage }}</ngb-alert>
<div class="icon-mt-close-dark icon " ngbTooltip="Close" tooltipClass="my-custom-class" placement="bottom"
        (click)="onClose()"></div>
<div class="header">
    <h5 class="m-8 font-bold" id="headValue">{{isEditAction ? 'Edit Device' : 'Add Device'}}</h5>
    <div>
        <app-radio-button [ngClass]="isEditAction?'disable':''" [radioList]="deviceTypes" [defaultValue]="this.radiovalue" (radioSelect)="Onchange($event)" [disabled]="isEditAction">
        </app-radio-button>
    </div>
</div>
<ng-template *ngIf="this.radiovalue =='Implant'; then displayImp ; else displaySpeech"></ng-template>
<ng-template #displayImp>
    <form class="form-class" [formGroup]="implantDeviceForm">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-textfield id="implantModelName" formControlName="implantModelName"
                        [outlined]="implantDeviceForm.controls.implantModelName.touched && implantDeviceForm.controls.implantModelName.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="implantDeviceForm['controls'].implantModelName.value" [type]="'text'"
                        [placeholder]="''" [label]="'Model Name'" [disabled]="isEditAction">
                    </app-textfield>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="implantDeviceForm.controls.implantModelName.touched && implantDeviceForm.controls.implantModelName.status == 'INVALID'">
                            Please Enter Model Name</div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-textfield id="implantSerialnumber" formControlName="implantSerialnumber"
                        [outlined]="implantDeviceForm.controls.implantSerialnumber.touched && implantDeviceForm.controls.implantSerialnumber.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="implantDeviceForm['controls'].implantSerialnumber.value" [type]="'text'"
                        [placeholder]="''" [label]="'Serial Number'" [disabled]="isEditAction">
                    </app-textfield>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="implantDeviceForm.controls.implantSerialnumber.touched && implantDeviceForm.controls.implantSerialnumber.status == 'INVALID'">
                            Please Enter Serial Number</div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <label class="textLabel required">Is Assigned</label>
                <div>
                    <app-admin-dropdown [outlined]="false" id="is_assigned" formControlName="is_assigned"
                        [inputLists]="dropdownLists" [selected]="implantDeviceForm['controls'].is_assigned.value"
                        (onDropdownSelect)="onTileDropdown($event)"></app-admin-dropdown>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="implantDeviceForm.controls.is_assigned.touched && implantDeviceForm.controls.is_assigned.status == 'INVALID'">
                            Please Enter IsAssigned Value</div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-text-area id="comments" formControlName="comments"
                        [defaultValue]="implantDeviceForm['controls'].comments.value" [placeholder]="''"
                        [label]="'Comments'">
                    </app-text-area>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="implantDeviceForm.controls.comments.touched && implantDeviceForm.controls.comments.status == 'INVALID'">
                            Please Enter Comments</div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngSwitch]="isEditAction">
            <div *ngSwitchCase="true">
                <div>
                    <app-button id="saveBtn" class="btn"
                        [disabled]="(!implantDeviceForm.dirty)" [buttonText]="'Update'"
                        (click)="onAddButtonClick()"></app-button>
                </div>
            </div>
            <div *ngSwitchCase="false">
                <div>
                    <app-button id="saveBtn" class="btn" [disabled]="!(implantDeviceForm.valid)" [buttonText]="'Save'"
                        (click)="onAddButtonClick()"></app-button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #displaySpeech>
    <form class="form-class" [formGroup]="SpDeviceForm">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-textfield id="spModelName" formControlName="spModelName"
                        [outlined]="SpDeviceForm.controls.spModelName.touched && SpDeviceForm.controls.spModelName.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="SpDeviceForm['controls'].spModelName.value" [type]="'text'"
                        [placeholder]="''" [label]="'Model Name'" [disabled]="isEditAction">
                    </app-textfield>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="SpDeviceForm.controls.spModelName.touched && SpDeviceForm.controls.spModelName.status == 'INVALID'">
                            Please Enter Model Name</div>
                    </div>
                </div>

            </div>

            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-textfield id="spSerialnumber" formControlName="spSerialnumber"
                        [outlined]="SpDeviceForm.controls.spSerialnumber.touched && SpDeviceForm.controls.spSerialnumber.status == 'INVALID' ? 'errorUnderline':'underline'"
                        [defaultValue]="SpDeviceForm['controls'].spSerialnumber.value" [type]="'text'"
                        [placeholder]="''" [label]="'Serial Number'" [disabled]="isEditAction">
                    </app-textfield>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="SpDeviceForm.controls.spSerialnumber.touched && SpDeviceForm.controls.spSerialnumber.status == 'INVALID'">
                            Please Enter Serial Number</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <label class="textLabel required">Is Assigned</label>
                <div>
                    <app-admin-dropdown [outlined]="false" id="is_assigned" formControlName="is_assigned"
                        [inputLists]="dropdownLists" [selected]="SpDeviceForm['controls'].is_assigned.value"
                        (onDropdownSelect)="onTileDropdown($event)"></app-admin-dropdown>
                        <div class="errorDiv">
                            <div class="errorMsg"
                                *ngIf="SpDeviceForm.controls.is_assigned.touched && SpDeviceForm.controls.is_assigned.status == 'INVALID'">
                                Please Enter IsAssigned Value</div>
                        </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6 pt-4">
                <div>
                    <app-text-area id="comments" formControlName="comments"
                        [defaultValue]="SpDeviceForm['controls'].comments.value" [placeholder]="''"
                        [label]="'Comments'">
                    </app-text-area>
                    <div class="errorDiv">
                        <div class="errorMsg"
                            *ngIf="SpDeviceForm.controls.comments.touched && SpDeviceForm.controls.comments.status == 'INVALID'">
                            Please Enter Comments</div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngSwitch]="isEditAction">
            <div *ngSwitchCase="true">
                <div>
                    <div id="saveBtn" class="btn">
                        <app-button [disabled]="!(SpDeviceForm.dirty)"
                            [buttonText]="'Update'" (click)="onAddButtonClick()"></app-button>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="false">
                <div>
                    <app-button id="saveBtn" class="btn" [disabled]="!(SpDeviceForm.valid)" [buttonText]="'Save'"
                        (click)="onAddButtonClick()"></app-button>
                </div>
            </div>
        </div>
    </form>
</ng-template>