import { Component, OnInit } from "@angular/core";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { Color } from "ng2-charts";
import { PatientSummaryService } from "src/app/_services/patient/patientSummary.service";
import { PatientCommonService } from "../patient-common.service";
import { Router } from "@angular/router";
import { AdminService } from "src/app/admin/services/admin.service";
import { UiUtilService } from "src/app/_services/ui-utils/ui-util.service";
import constant from "src/constants/MTConstants";

@Component({
  selector: "app-assigned-lesson",
  templateUrl: "./assigned-lesson.component.html",
  styleUrls: ["./assigned-lesson.component.scss"],
})
export class AssignedLessonComponent implements OnInit {
  tileBlockTitle: string = "";
  headerLists: any[] = constant.prelingual.statLegends ;
  public barChartOptions: any = {
    responsive: true,
    legend: {
      position: "top",
      align: "end",
      fullWidth: true,
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        fontSize:9,
        fontWeight:700,
        fontFamily:"HCLTechRoobert",
        fontColor:"#19191E"
        
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize:12,
            // fontWeight: 600,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0"
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: true,
          },
          ticks: {
            beginAtZero: true,
            fontFamily:"HCLTechRoobert",
            fontColor:"#8291A0",
            userCallback: function (label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          let percentage = "";
          return percentage;
        },
        anchor: "centre",
        align: "centre",
      },
    }
  };

  public barChartType: ChartType = "bar";
  public barChartLegend = true;

  public barChartclr: Color[];
  
  public barChartColors: Color[] = [
    { backgroundColor: "#FFECC7" },
    { backgroundColor: "#BEEBB4" },
    { backgroundColor: "#FFBED7" },
  ];

  barChartLabels: any;
  barChartData: { data: any; label: string }[];
  statisticsList: any;
  statisticsTitle: any;
  assignedCourses: any;
  pendingCourses: any;
  completedCourses: any;
  patientDetail: any;
  patientID: any;
  selectedLesson: any;
  therapistId: any;
  statsList: any;
  levelId: any;

  constructor(
    private api: RestApiService,
    private localStorage: LocalStorageService,
    private patientCommonService: PatientCommonService,
    private router: Router,
    private uiUtils: UiUtilService,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.therapistId = this.localStorage.get(constant.loginScreen.therapistId);
    this.selectedLesson = this.localStorage.get(constant.prelingual.selectedLesson);
    this.tileBlockTitle =
      this.selectedLesson.courseLevel + " - " + this.selectedLesson.courseName + " - " + this.selectedLesson.goalName;
    this.patientDetail = this.localStorage.get(constant.currentSelectedPatient);
    this.patientCommonService.searchSubscriber$.subscribe((searchString) => {
      this.levelId = searchString;
  });
    this.patientID = this.patientDetail.id || this.patientDetail.patientId;
    // this.courseStatistics();
    this.patientStatistics();
  }

  chartHovered($event) {}

  chartClicked($event) {}
  //API for statistics of Patient Lessons.
  patientStatistics(): void {
    let requestBody = {
      patientId: this.patientID,
      therapistId: this.therapistId,
      levelId: this.levelId,
      courseId: this.selectedLesson.courseId,
      goalId: this.selectedLesson.goalId
    };
    this.api.fetchReports(requestBody).subscribe((res) => {
    //   res = {
    //     "title": "Assesment Reports",
    //     "assessmentReport": [
    //         {
    //             "assessmentName": "Loud Out More",
    //             "percentageofCorrectAnswer": null,
    //             "percentageOfWrongAnswer": null,
    //             "totalQuestions": 10,
    //             "totalAttempts": 5,
    //             "wrongAnswer": 2,
    //             "correctAnswer": 3
    //         },
    //         {
    //           "assessmentName": "Banging Hammer",
    //           "percentageofCorrectAnswer": null,
    //           "percentageOfWrongAnswer": null,
    //           "totalQuestions": 10,
    //           "totalAttempts": 5,
    //           "wrongAnswer": 2,
    //           "correctAnswer": 3
    //       }
    //     ]
    // }
      this.statisticsList = res;
      this.statsList = this.statisticsList.assessmentReport.length;
      this.barChartclr = this.barChartColors;
      let wrongAnswer = this.statisticsList.assessmentReport.map(
        (data) => data.wrongAnswer
      );
      let correctAnswer = this.statisticsList.assessmentReport.map(
        (data) => data.correctAnswer
      );
      let totalAttempts = this.statisticsList.assessmentReport.map(
        (data) => data.totalAttempts
      );

      // if(this.completedCourses >0) {
      this.barChartData = [
        { data: totalAttempts, label: "Number Of Attempts" },
        { data: correctAnswer, label: "Correct " },
        { data: wrongAnswer, label: "In Correct" },
      ];
      this.barChartLabels = this.statisticsList.assessmentReport.map(
        (data) => data.assessmentName
      );
      
      // }
    });
   
  }

  onClose() {
    this.uiUtils.DismissshowActivitiesStatistics();
  }
}
