import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  ViewChild,
  ElementRef,
  Output,
} from "@angular/core";
import { NgModule } from '@angular/core';
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: 'app-password-textfield',
  templateUrl: './password-textfield.component.html',
  styleUrls: ['./password-textfield.component.scss']
})
export class PasswordTextfieldComponent implements OnInit, ControlValueAccessor {
  value: any = "";
  @Input() label: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() defaultValue: string;
  @Input() outlined: string = "fullBorder";
  @ViewChild("input") inputRef: ElementRef;
  isFocus = false;
  hide = true;



  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  onInputFocus() {
    this.isFocus = true;
    this.inputRef.nativeElement.focus();
  }

  onInputBlur() {
    this.isFocus = false;
    if (this.defaultValue != "") {
      this.isFocus = true;
    }
  }
  onInputChange(e) {
    this.defaultValue = e;
    this.isFocus = true;
  }
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onInputBlur = fn;
  }

  toggleVisibility(): void {
    this.onInputFocus();
    this.hide = !this.hide;
  }

  restrictSpaces(event){
      if (event.keyCode == 32) {
          return false;
      }
  }

  }
