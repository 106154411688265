<label for="dateofbirth" class="textLabel font-regular required">{{label}}</label>
<form class="form-inline">
  <div class="form-group" style="width:100%;">
    <div class="input-group" style="width:100%;">
      <input class="form-control" #input
        [ngClass]="outlined === 'fullBorder' ? 'form-rounded' : outlined === 'errorUnderline'? 'error-border':'form-underline'"
        name="dateofbirth" placeholder="dd-mm-yyyy" [(ngModel)]="defaultValue" (ngModelChange)="onInputChange($event)"
        [minDate]="minDate" ngbDatepicker #d="ngbDatepicker" (focus)="onInputFocus()" (blur)="onInputBlur()"
        [disabled]="disabled" [maxDate]="maxDate" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar"
          [ngClass]="outlined === 'errorUnderline' ? 'calender-red' : null" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div>
</form>