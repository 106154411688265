<div class="containerDiv">
    <div class="modal-header text-left">
        <h6>{{title}}</h6>
        <div>
            <app-radio-button [label]="''" [radioList]="catList"
                [defaultValue]="selectedCat" (radioSelect)="selectCat($event)">
            </app-radio-button>
        </div>
    </div>
    <div class="icon-mt-close-white closeIcon" (click)="onClose()"></div>

    <div class="formRow">
        <canvas baseChart [datasets]="barChartData"
            [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
            [colors]="barChartColors" [chartType]="barChartType">
        </canvas>
    </div>
    <!-- <div class="modal-footer">
        <app-button id="changePassSubmit" [disabled]="!changePasswordForm.valid" (click)="onSubmit()"
            [buttonText]="'Submit'"></app-button>
    </div> -->
</div>