import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringService {
  static joinString(...strings) {
    return strings.join(' ');
}

static hyphenString(...strings) {
  return strings.join(' - ');
}

static concatString(...strings) {
  return strings.concat([,]);
}

static compareString(firstString,secondString) {
  return firstString === secondString
}
  constructor() {
    
   }
}
