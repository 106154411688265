<div style="padding: 32px;">
    <div class="modal-body text-center">
        <p style="margin-bottom: 5px;color: #19191E;
        font-weight: 700;">{{message}}</p>
   
    <div class="action-buttons">
        <button type="button" class="btn btn-primary action-button button-ok" (click)="onOk()">
            <div class="yes-text">Yes</div>
        </button>
        <button type="button" class="btn btn-primary action-button button-ok" (click)="onCancel()">
            <div class="yes-text">No</div>
        </button>
    </div>
   </div>
</div>