<div (focus)="onInputFocus()" >
    <label [ngClass]="label ? 'textLabel required' : 'noLabel'">{{ label }}</label>
    <div class="flex">
        <div class="radio" *ngFor="let list of radioList;let i=index;trackBy: trackByFn">
            <input id="{{list.label}}" #input name="radio" type="radio" (click)="onClick(list.label)"
                [checked]="list.checked" [disabled]="list.disabled" (focus)="onInputFocus()" (blur)="onInputBlur()">
            <label for="{{list.label}}" class="radio-label textLabelValue"
                style="margin-bottom:0 !important;">{{list.label}}</label>
        </div>
    </div>
</div>