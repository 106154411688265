import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export class DialogOptions {
  message: string;
  title?: string;
  data?:any;
}

export enum DialogDefaults {
  OK_BUTTON_TEXT = "Confirm",
  CANCEL_BUTTON_TEXT = "Cancel",
  TITLE = "Confirm",
}

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() message: string;
  @Input() title = DialogDefaults.TITLE;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.activeModal.close(false);
  }

  onOk(): void {
    this.activeModal.close(true);
  }
}
