<div class="player-wrapper">

    <!-- <h3>Video Player with Controls</h3> -->

    <vg-player style="border-radius: 8px !important;">
        <vg-overlay-play></vg-overlay-play>
        <!-- <vg-buffering></vg-buffering> -->

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <!-- <vg-playback-button></vg-playback-button> -->

            <!-- <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display> -->

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <!-- <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

            <vg-track-selector></vg-track-selector>
            <vg-mute></vg-mute>
            <vg-volume></vg-volume> -->

            <vg-fullscreen></vg-fullscreen>
        </vg-controls>



        <video #media [vgMedia]="media" id="singleVideo" preload="auto">
            <source [src]="videoUrl" type="video/mp4">


        </video>
    </vg-player>

</div>