import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { debounceTime } from "rxjs/operators";
import { RestApiService } from "../../_services/rest-api/rest-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "../../_services/local-storage/localstorage.service";
import { DashboardService } from "src/app/_services/Dashboard/dashboard.service";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
  @Output() public onKeypress = new Subject<any>();
  @Input() searchListArray: any;
  @Input() lessonListFlag: boolean = false;
  @ViewChild("searchbutton") element: ElementRef;
  searchTextChanged = new Subject<string>();
  subscription = new Subscription();
  searchList: any = [];
  searchValue: any;
  therapistId: any;
  showInputSearchFlag: boolean = false;
  showSearchListFlag: boolean = false;
  errorMsgFlag: boolean = false;
  selectedLessonLevelId: any;
  selectedLevel: any;
  flowType: any;

  constructor(
    private api: RestApiService,
    private router: Router,
    private localStorage: LocalStorageService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private elem: ElementRef
  ) {
    this.therapistId = this.localStorage.get("therapistId");
  }

  ngOnInit(): void {
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe((term) => {
      this.selectedLessonLevelId = this.localStorage.get(
        "selectedLessonLevelId"
      );
      this.selectedLevel = this.localStorage.get("selectedLevel");
      this.flowType = this.localStorage.get("flowType");
      if (this.lessonListFlag) {
        this.errorMsgFlag = false;
        this.lessonList();
      } else {
          this.errorMsgFlag = false;
          this.fetchAllPatients(term);
      }
    });
  }

  lessonList(): void {
    let request = {
      courseName: this.searchValue,
      courselevel: this.selectedLessonLevelId,
    };
    this.api.getSearchLessonDetailList(request).subscribe((res) => {
      if (res.response.length) {
        this.searchList = res.response;
        this.showSearchListFlag = true;
      } else {
        this.errorMsgFlag = true;
      }
    });
  }

  //post Lingual
  fetchAllPatients(searchValue) {

      this.searchList = this.searchListArray.filter((x) =>
        (x.patientFirstName+" "+x.patientLastName).toLowerCase().includes(searchValue) 
      );
      if (this.searchList.length) {
        this.showSearchListFlag = true;
      } else {
        this.errorMsgFlag = true;
      }

  }

  //Get all patients for search.
  //Get all patients for search.
  // getAllPatients(searchValue) {
  //   let requestBody = {
  //     keyword: searchValue,
  //     therapistId: this.therapistId,
  //     pageNumber: "1",
  //     recordsPerPage: 10,
  //   };
  //   this.api.getPatients(requestBody).subscribe((res) => {
  //     if (res.data.patientList.length) {
  //       this.searchList = res.patientList;
  //       this.showSearchListFlag = true;
  //     } else {
  //       this.errorMsgFlag = true;
  //     }
  //   });
  // }

  //On search of patient
  onSearch(e) {
    this.searchValue = e.target.value;

    if (e.target.value) {
      this.searchTextChanged.next(e.target.value);
    } else {
      this.searchList = [];
    }
  }
  //On Select of individual patient searchh.
  onSelectRow(value) {
    if (this.lessonListFlag) {
      this.localStorage.set("selectedLesson", value);
      this.localStorage.set("selectedLevel", this.selectedLevel);
      this.router.navigateByUrl(
        "therapist/preLingual/patientSummary/lessonGoalPoints"
      );
    } else {
      if (this.flowType === "preLingual") {
        this.localStorage.set("currentSelectedPatient", value);
        this.router.navigateByUrl("therapist/preLingual/patientSummary/stats");
      } else if (this.flowType === "postLingual") {
        this.localStorage.set("currentSelectedPatient", value);
        // this.router.navigate(["therapist/postLingual/reports/home"]);
        this.router.navigateByUrl("therapist/postLingual/reports/home");
      }
    }
  }
  //On Select of individual patient searchh.
  // onSelectRow(value) {
  //   if (this.lessonListFlag) {
  //     this.localStorage.remove("selectedLesson");
  //     // this.router.navigateByUrl("patientDetails/summary/lessonGoalPoints");
  //     // this.router.navigate(["patientDetails/summary/lessonGoalPoints"], {
  //     //   queryParams: { lessonDetails: value },
  //     // });
  //     this.router.navigate(["patientDetails/summary/lessonGoalPoints"], {
  //       state: { value },
  //     });
  //   } else {
  //     this.localStorage.set("currentSelectedPatient", value);
  //     this.router.navigateByUrl("patientDetails/summary");
  //   }
  // }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  @HostListener("document:click", ["$event"])
  DocumentClick(event: Event) {
    // console.log(event.target);
    if (this.elem.nativeElement.contains(event.target)) {
      // console.log("inside");
      if (!this.showInputSearchFlag) {
        this.showInputSearchFlag = !this.showInputSearchFlag;
      }
    } else {
      this.showInputSearchFlag = false;
      this.searchList = [];
      this.searchValue = "";
      this.errorMsgFlag = false;
    }
  }
}
