<table class="table table-responsive-sm">
    <ng-template *ngIf="patients?.length; then displayPatientList; else noPatientList"></ng-template>
    <ng-template #displayPatientList>
        <thead style="margin-bottom:10px;">
            <tr class="font-medium">
                <td>Name</td>
                <td style="white-space: nowrap;">
                    Age Analysis
                    <div
                        style="font-size: 10px; white-space: nowrap; color: #8291A0;font-weight: 500;position: absolute;">
                        <span style="cursor: pointer;" ngbTooltip="Chronological Age" tooltipClass="my-custom-class"
                            placement="bottom">Age</span> |
                        <span style="cursor: pointer;" ngbTooltip="Chronological Implant Age"
                            tooltipClass="my-custom-class" placement="bottom">Implant age</span> |
                        <span style="cursor: pointer;" ngbTooltip="Chronological Gap" tooltipClass="my-custom-class"
                            placement="bottom">Gap</span>
                    </div>
                </td>
                <td>Gender</td>
                <td>Course Level</td>
                <td> Course Progress</td>
                <td style="width: 280px;">Comments</td>
                <td>Action</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let patient of patients; let i = index; trackBy: trackByFn" class="font-regular" id="{{i}}"
                (mouseover)="onMouseOver(patient.courseLevel,i)" (mouseout)="onmouseout(patient.courseLevel,i)">
                <td>{{ patient.patientFirstName+" "+patient.patientLastName | titlecase }}</td>
                <td [ngClass]="patient.patientAge == '-' ?'alignCenter':null">
                    <div>
                        {{ patient.patientAge }} | {{ patient.patientImplantAge }} |
                        {{ patient.patientImplantGap }}
                    </div>
                </td>
                <td [ngClass]="patient.patientGender == '-' ?'alignCenter':null">{{ patient.patientGender }}</td>
                <ng-container *ngIf="flowType === 'preLingual'; then preLingualRows; else postLingualRows">
                </ng-container>
                <ng-template #preLingualRows>
                    <td [ngClass]="patient.courseLevel == 'un assigned' ?'alignCenter':null">
                        <span
                            [ngClass]="patient.courseLevel == 'Basic' ?'green': patient.courseLevel == 'Intermediate' ?'red':patient.courseLevel == 'un assigned'?'grey':'pink'"
                            id="td{{i}}">{{ patient.courseLevel }}</span>
                    </td>
                    <td style="
               width: 35px;">
                        <span [ngClass]="isStatusAboveLimit(patient) ? 'ok' : 'not-ok'">{{
                            patient.completedCourses
                            }}</span><span style="color: #d6d6d6; font-size: 12px">/{{ patient.totalCourses }}</span>
                    </td>
                    <td style="width:90px;">
                        <ngb-progressbar *ngIf="patients" id="pdProgBar"
                            [type]="isStatusAboveLimit(patient) ? 'primary' : 'success'" height="8px"
                            [value]="getCompletedProgress(patient)"></ngb-progressbar>
                    </td>
                    <td style="width: 280px;">
                        <div [ngClass]="patient.notes == 'NA' ?'alignCenter':null" class="comments"
                            [ngbTooltip]="patient.notes=='NA'? '':patient.notes" tooltipClass="my-custom-class"
                            placement="top" container="body">
                            {{ patient.notes }}
                        </div>
                    </td>
                </ng-template>
                <ng-template #postLingualRows>
                    <td [ngClass]="patient.courseLevel == 'un assigned' ?'alignCenter':null">
                        <span
                            [ngClass]="patient.courseLevel == 'Basic' ?'green': patient.courseLevel == 'Intermediate' ?'red':patient.courseLevel == 'un assigned'?'grey':'pink'"
                            id="td{{i}}">{{ patient.courseLevel }}</span>
                    </td>
                    <td style="
                width: 35px;">
                        <span [ngClass]="isStatusAboveLimit(patient) ? 'ok' : 'not-ok'">{{
                            patient.completedCourses
                            }}</span><span style="color: #d6d6d6; font-size: 12px">/{{ patient.totalCourses }}</span>
                    </td>
                    <td style="width:90px;">
                        <ngb-progressbar *ngIf="patients" id="pdProgBar"
                            [type]="isStatusAboveLimit(patient) ? 'primary' : 'success'" height="6px"
                            [value]="getCompletedProgress(patient)"></ngb-progressbar>
                    </td>
                    <td style="width: 280px;">
                        <div [ngClass]="patient.notes == 'NA' ?'alignCenter':null" class="comments"
                            [ngbTooltip]="patient.notes=='NA'? '':patient.notes" tooltipClass="my-custom-class"
                            placement="top" container="body">
                            {{ patient.notes }}
                        </div>
                    </td>
                </ng-template>
                <td class="action-cell">
                    <div class="d-inline-flex">
                        <div class="icon-mt-view-eye d-flex m-0 icon-action"
                            [ngbTooltip]="flowType === 'preLingual' ? 'Patient Detail Summary' : 'View Patient reports'"
                            tooltipClass="my-custom-class" placement="bottom" container="body"
                            (click)="onView(patient)"></div>
                        <div class="icon-mt-edit d-flex edit-icon icon-action" ngbTooltip="Edit Patient Details"
                            tooltipClass="my-custom-class" placement="bottom" container="body"
                            (click)="onEdit(patient)"></div>
                        <div class="icon-mt-delete d-flex  icon-action m-0" ngbTooltip="Remove Patient"
                            tooltipClass="my-custom-class" placement="bottom" container="body"
                            (click)="onDelete(patient)"></div>
                        <!-- <div class="icon-mt-comment d-flex  icon-action"
                            [ngbTooltip]="patient.notes" tooltipClass="my-custom-class" placement="left" container="body"
                            style="margin-right: 0px;">
                        </div> -->
                    </div>
                </td>
            </tr>
        </tbody>
    </ng-template>
    <ng-template #noPatientList>
        <div class="nodata-class">
            <span>No data available</span>
        </div>
    </ng-template>
</table>