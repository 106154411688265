import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() disabled: boolean;
  @Output() btnClick = new EventEmitter();
  @Input() iconClass: string;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.iconClass);
  }
  onBtnClick($event) {
    this.btnClick.emit($event);
  }
}
