import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownComponent } from "../components/dropdown/dropdown.component";
import { HeaderComponent } from "../components/header/header.component";
import { SearchBarComponent } from "../components/search-bar/search-bar.component";
import { TextfieldComponent } from "../components/textfield/textfield.component";
import { PasswordTextfieldComponent } from "../components/password-textfield/password-textfield.component";
import { ButtonComponent } from "../components/button/button.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  NgbProgressbarModule,
  NgbDropdownModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { LineChartComponent } from "../components/charts/line-chart/line-chart.component";
import { BarChartComponent } from "../components/charts/bar-chart/bar-chart.component";
import { ChartBaseComponent } from "../components/charts/chart-base/chart-base.component";
import { ChartsModule } from "ng2-charts";
import { DatePickerComponent } from "../components/date-picker/date-picker.component";
import { TextAreaComponent } from "../components/text-area/text-area.component";
import { LessonCardComponent } from "../components/lesson-card/lesson-card.component";
import {
  VgCoreModule,
  VgControlsModule,
  VgOverlayPlayModule,
  VgBufferingModule,
  VgStreamingModule,
} from "ngx-videogular";
import { UserProfileComponent } from "../components/user-profile/user-profile.component";
import { RadioButtonComponent } from "../components/radio-button/radio-button.component";
import { DropdownWithSearchComponent } from "../components/dropdown-with-search/dropdown-with-search.component";
import { SelectImplantDeviceComponent } from "../components/select-implant-device/select-implant-device.component";
import { CircleProgressComponent } from "../components/circle-progress/circle-progress.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { SatisticsCardComponent } from "../rehab/pre-lingual/dashboard/ui-components/satistics-card/satistics-card.component";
import { VideoPlayerComponent } from "../rehab/pre-lingual/patient-details/sub-components/video-player/video-player.component";
import { FooterComponent } from "../rehab/sub-components/footer/footer.component";
import { AddPatientComponent } from "../components/add-patient/add-patient.component";
import { PatientListTableComponent } from "../rehab/pre-lingual/dashboard/ui-components/patient-list-table/patient-list-table.component";
import { DropdownMultiComponent } from "../components/dropdown-multi/dropdown-multi.component";
import { CardComponent } from "../components/card/card.component";
import { DropdownForCourseContentComponent } from "../components/dropdown-for-course-content/dropdown-for-course-content.component";
import { AllCourseCardComponent } from "../components/all-course-card/all-course-card.component";
import { AdminDefaultCardComponent } from "../components/admin-default-card/admin-default-card.component";


@NgModule({
  declarations: [
    SearchBarComponent,
    HeaderComponent,
    DropdownComponent,
    ButtonComponent,
    TextfieldComponent,
    LineChartComponent,
    BarChartComponent,
    ChartBaseComponent,
    DatePickerComponent,
    TextAreaComponent,
    SatisticsCardComponent,
    LessonCardComponent,
    VideoPlayerComponent,
    UserProfileComponent,
    RadioButtonComponent,
    FooterComponent,
    DropdownWithSearchComponent,
    AddPatientComponent,
    SelectImplantDeviceComponent,
    PatientListTableComponent,
    CircleProgressComponent,
    CardComponent,
    DropdownForCourseContentComponent,
    AllCourseCardComponent,
    AdminDefaultCardComponent,
    PasswordTextfieldComponent
    // DropdownMultiComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbDropdownModule,
    ChartsModule,
    NgbModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      
    }),
    
  ],
  exports: [
    SearchBarComponent,
    HeaderComponent,
    DropdownComponent,
    ButtonComponent,
    TextfieldComponent,
    LineChartComponent,
    BarChartComponent,
    ChartBaseComponent,
    FormsModule,
    ReactiveFormsModule,
    DatePickerComponent,
    TextAreaComponent,
    SatisticsCardComponent,
    LessonCardComponent,
    VideoPlayerComponent,
    UserProfileComponent,
    RadioButtonComponent,
    FooterComponent,
    DropdownWithSearchComponent,
    AddPatientComponent,
    SelectImplantDeviceComponent,
    PatientListTableComponent,
    CircleProgressComponent,
    CardComponent,
    DropdownForCourseContentComponent,
    AllCourseCardComponent,
    AdminDefaultCardComponent,
    PasswordTextfieldComponent,
    // DropdownMultiComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
