<div (focus)="onInputFocus()" style="position: relative;">
    <div class="placeHolder" *ngIf="selectedVal">{{SelectHere}}</div>
    <label class="dropdownLabel">
            <select [id]="dropdownId" class="dropdownSelect stylee"
            [ngClass]="outlined === 'fullBorder' ? 'form-rounded' : outlined === 'halfborder'? 'form-rounded-half':'form-underline'" #input [(ngModel)]="selected"
                    name="customDropDown" [disabled]="disabled" (ngModelChange)="onOptionsSelected($event)"
                    (focus)="onInputFocus()" (blur)="onInputBlur()">
                    <option *ngFor="let list of inputLists;let i=index;trackBy: trackByFn" [ngValue]="list">{{list}}
                    </option>
            </select>
    </label>
</div>

<!-- [ngClass]="{'form-rounded': outlined==1, 'form-rounded-half': outlined==2, 'form-underline': outlined==3}" -->
<!-- [ngClass]="outlined === 'fullBorder' ? 'form-rounded' : outlined === 'errorUnderline'? 'form-rounded-half':'form-underline'" -->
