<div (click)="onInputFocus()">
    <!-- <label class="textLabel font-regular">{{label}}</label> -->
    <label [ngClass]="outlined === 'fullBorder' ? 'textLabel font-regular' : 'textLabel font-regular required '">{{label}}</label>
    <input (keypress)="restrictSpaces($event)" #input  class="form-control" [type]="hide ? 'password' : 'text'"
      [ngClass]="outlined === 'fullBorder' ? 'form-rounded' : outlined === 'errorUnderline'? 'error-border':'form-underline'"
      [disabled]="disabled" [placeholder]="placeholder" [(ngModel)]="defaultValue" (ngModelChange)="onInputChange($event)"
      (focus)="onInputFocus()" (blur)="onInputBlur()">
      <div class="eye" *ngIf="this.isFocus == true">
        <i class="fa" [ngClass]="{'fa-eye': hide, 'fa-eye-slash': !hide}" (click)="toggleVisibility()"></i>
      </div>
  </div>
