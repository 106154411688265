<div *ngIf="tyleType==1">
    <div class="tileHeader">{{tileHeader}}</div>
    <div style="display:flex;">
        <div class="iconDiv"></div>
        <div class="contentDiv">{{tileContent}}</div>
    </div>
    <div class="bottomDiv">
        <app-dropdown [inputLists]="dropdownLists" [selected]="dropdownSelected"
            (onDropdownSelect)="onTileDropdown($event)"></app-dropdown>
    </div>
</div>

<div *ngIf="tyleType==2" [style.background-color]="tileColor" class="mainDiv2">
    <div class="tileHeaderDiv">{{tileHeader}}</div>
    <div style="display:flex;">
        <div style="flex:1;border-right:1px solid #ffffff;">
            <div class="tileCount">{{tileCount1}}</div>
            <div class="tileContent">{{tileContent1}}</div>
        </div>
        <div style="flex:1;">
            <div class="tileCount">{{tileCount2}}</div>
            <div class="tileContent">{{tileContent2}}</div>
        </div>
    </div>
</div>